
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { catchError, filter, of, Subscription, tap } from 'rxjs';
import {
  OnInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnDestroy,
  ElementRef
} from '@angular/core';
import { SearchDocumentListService } from 'src/app/service/searchDocumentList.service';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { CONSTANTS } from 'src/app/constants';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss']
})
export class FilterSearchComponent implements OnInit, OnDestroy {
  /* add input for modalOpen */
  @Input() ismodalOpen: boolean=false;
  @Input() isClearable:boolean=false;
  @Output() modalOpenChange = new EventEmitter<boolean>();
  @ViewChild('selectProductLines') ngSelect: NgSelectComponent;
  @Input() searchInput:string='';
  @Input() searchInTitle:boolean=false
  public urlproductLineData:any=[];
  public urlproductsData:any=[];
  public urlcomponentsData:any=[];
  public urlversionsData:any=[];
  public urldocumentTypeData:any=[];
  public urlaudiencesetData:any=[];
  public selectedproductLines:any=[];
  public previousUrl: string;
  private currentUrl: string;
  public routerSubscription: Subscription;
  public isFirstNavigation: boolean = true;
  private setFilterSubscription: Subscription;
  public productLineSubscription: Subscription;
  public filterCloseData: Subscription;
  public isFilterApplied:boolean=false;
  public filteredData:any
  public mainFilteredData:any
  public isAppliedFilter:boolean=true;
  @ViewChild('selectProductItem') selectProductLines;
  @ViewChild('selectProduct') selectProductItem;
  @ViewChild('selectVersion') selectVersionItem;
  @ViewChild('selectComponent') selectComponentItem;
  @ViewChild('selectAudience') selectAudienceItem;
  @Output() clearuserSearch:EventEmitter<any>=new EventEmitter();
  @ViewChild('filterDiv') filterDiv: ElementRef;
  public paramData:any=['productLine','products','components','versions','documentType','audienceset'];
  productLines=[];
  public selectedproductItems:any=[];
  productItems=[];
  public selectedcomponentItems:any=[];
  componentItems=[];
  public selectedversionItems:any=[];
  versionItems=[];
  public selectedaudienceItems:any=[];
  docTypeItems=[];
  audienceItems=[];
  public selecteddocTypeItems:any=[];
  public isFilterSet:boolean=false;
  public verSionParam:any='';

public queryParams:any='';
  isApplied: boolean;
  public audienceTypeInternal: any = localStorage.getItem(CONSTANTS.STORAGE.isInternal);
  private queryParamsSubscription: Subscription;
  constructor(private searchdocumentlistService: SearchDocumentListService, private router: Router,private route:ActivatedRoute,private eventBusService:AmdocsEventBusService,private userService:UserService) {
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.isFilterSet=true;
        if(this.currentUrl=='/'){
          this.eventBusService.emit(CONSTANTS.EVENTS.FILTER_COUNT,0)
          this.filterSearchApi();
          this.clearuserSearch.emit('');
        }
      });
      const navigation = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
      if ((navigation.type === 'reload' || navigation.type === 'navigate') && !this.isFilterSet && window?.location?.pathname === '/') {
        this.filterSearchApi();
      }

   this.setFilterSubscription = this.eventBusService.on('setFilterData', (data) => {
    this.getFilterData();
    },true);

    this.filterCloseData=this.eventBusService.on(CONSTANTS.EVENTS.FILTER_CLOSE_EVENT_DATA, (data) => {
      const mapping = {
        'productLineId': { urlData: 'urlproductLineData', selectedData: 'selectedproductLines' },
        'productId': { urlData: 'urlproductsData', selectedData: 'selectedproductItems' },
        'componentId': { urlData: 'urlcomponentsData', selectedData: 'selectedcomponentItems' },
        'versionId': { urlData: 'urlversionsData', selectedData: 'selectedversionItems' },
        'docTypeId': { urlData: 'urldocumentTypeData', selectedData: 'selecteddocTypeItems' },
        'audienceId': { urlData: 'urlaudiencesetData', selectedData: 'selectedaudienceItems' }
      };

      const map = mapping[data.id];
      if (map) {
        this[map.urlData] = data['items'].map(item => item.name);
        this[map.selectedData] = data['items'];
      }

      this.setParams();
    }, true);

    this.eventBusService.on('clearAllSelections', (data) => {
      let pData=['productLine','products','components','versions','documentType','audienceset'];
      pData.forEach((element) => {
      this['url' + element + 'Data'] = [];
      });
      this.queryParams ={};
      this.selectedproductLines=[];
      this.selectedproductItems = [];
      this.selectedcomponentItems=[];
      this.selectedversionItems=[];
      this.selecteddocTypeItems=[];
      this.selectedaudienceItems=[];
      this.resetFilter('clear');

    }, true);

    this.eventBusService.on('clearInputSearch', (data) => {
      this.clearuserSearch.emit('');
    },true);

    this.eventBusService.on(CONSTANTS.EVENTS.CLEAR_HOME_SELECTIONS, (data) => {
      if(this.currentUrl=='/'){
        this.clearuserSearch.emit('');
     }
    }, true);

    this.eventBusService.on('cancelOutsideFilterApply', (data) => {
    //  this.resetFilter('filterOutside');

    this.isApplied=true;
    let productLineObject;
    productLineObject={"productLine": this.mainFilteredData?.productLine,"products": this.mainFilteredData?.products,"versions": this.mainFilteredData?.versions,"components": this.mainFilteredData?.components,"docType": this.mainFilteredData?.docType,"searchKey": this.mainFilteredData?.searchKey,"audience": this.mainFilteredData?.audience}
    if(this.router.url==='/productline'){
      this.userService.currentProductLine.subscribe(productLine => {
          this.urlproductLineData=productLine?.name ? [productLine?.name] : [];
          this.selectedproductLines = productLine?.name ? [productLine?.name] : [];
          productLineObject={"productLine": this.selectedproductLines,"products": [],"versions": [],"components":[],"docType": [],"searchKey": this.searchInput,"audience": []}

      });
     }
    setTimeout(() => {
      this.setCancelData(productLineObject);
    }, 200); // Delay to ensure content is loaded
     if(this.isAppliedFilter){
      this.fetchData(productLineObject,'isCancelOutside');
     }
    }, true);

    this.eventBusService.on(CONSTANTS.EVENTS.OPEN_FILTER, (data) => {
      setTimeout(() => {
        if (this.filterDiv && this.filterDiv.nativeElement) {
          this.filterDiv.nativeElement.scrollTop = data ? 0 : this.filterDiv.nativeElement.scrollTop;
        }
      }, CONSTANTS.TIMEOUT.filterScrollDelay);
    }, true);

    this.eventBusService.on('updateSearchInput', (data) => {
      this.mainFilteredData =  data;
      this.isAppliedFilter=true;
    },true);

    this.eventBusService.on('productLineFilter', (data) => {
      this.mainFilteredData =  data;
    },true);

    this.eventBusService.on('filterOpened', (data) => {
      this.setLineFilter();
    }, true);

    this.eventBusService.on(CONSTANTS.EVENTS.FACET_DATA, (data) => {
      const results = data?.facetsData;
      const selectedData = data?.productLineObject[0];
      const resultSet = results?.field;

      this.productItems = resultSet?.Prdct?.counts;
      this.componentItems = resultSet?.Cmpnt?.counts;
      this.versionItems = this.versionSorting(resultSet?.FVN?.counts);
      this.docTypeItems = resultSet?.DocTyp?.counts;
      this.audienceItems = resultSet?.PermsSts?.counts;
      this.productLines = resultSet?.PL?.counts;

      this.selectedproductLines = selectedData?.productLines || [];
      this.selectedproductItems = selectedData?.productItems || [];
      this.selectedcomponentItems = selectedData?.componentItems || [];
      this.selectedversionItems = selectedData?.versionItems || [];
      this.selecteddocTypeItems = selectedData?.docTypeItems || [];
      this.selectedaudienceItems = selectedData?.audienceItems || [];

      this.urlproductLineData = this.mapSelectedData(selectedData, 'productLines');
      this.urlproductsData = this.mapSelectedData(selectedData, 'productItems');
      this.urlcomponentsData = this.mapSelectedData(selectedData, 'componentItems');
      this.urlversionsData = this.mapSelectedData(selectedData, 'versionItems');
      this.urldocumentTypeData = this.mapSelectedData(selectedData, 'docTypeItems');
      this.urlaudiencesetData = this.mapSelectedData(selectedData, 'audienceItems');
    }, true);

   this.productLineSubscription= this.eventBusService.on(CONSTANTS.EVENTS.PRODUCTLINE, (data) => {
      if(data){
      this.eventBusService.emit(CONSTANTS.EVENTS.FILTER_COUNT,1);
      this.urlproductLineData =[data];
      this.getProductLines(this.urlproductLineData);
      this.selectedproductLines=[{name:data}];
      this.resetFilter('clear');
      }
    },true);

    this.eventBusService.on(CONSTANTS.EVENTS.SEARCH_INPUT,searchValue => {
     this.searchInput=searchValue.userSearch;
     this.searchInTitle=searchValue.searchInTitle;
     this.queryParams =  {"productLine": encodeURIComponent(this.selectedproductLines.map(item => item.name)),"products": encodeURIComponent(this.selectedproductItems.map(item => item.name)),"versions": encodeURIComponent(this.selectedversionItems.map(item => item.name)),
      "components": encodeURIComponent(this.selectedcomponentItems.map(item => item.name)),
      "documentType":encodeURIComponent(this.selecteddocTypeItems.map(item => item.name)),"searchKey":this.searchInput,"audienceset": encodeURIComponent(this.selectedaudienceItems.map(item => item.name)),"size":"1","page":"0",'inTitleOnly':this.searchInTitle}
     this.applyFilter();
    }, true);

    this.eventBusService.on('getProductList', (data) => {
      this.productLines=data;
    },true);

    this.eventBusService.on('closeFilter', (data) => {
      this.ismodalOpen=false;
      this.modalOpenChange.emit(false);
    },true);

    this.eventBusService.on(CONSTANTS.EVENTS.UPDATE_FILTER_ITEMS, (data) => {
      let type = Object.keys(data)[0]; // Assuming the type is the first key in the data object
      switch (type) {
        case 'productLines':
          this.urlproductLineData = data[type];
          this.selectedproductLines=this.selectedproductLines.filter(item =>
            data[type].some(filter => item.name.toLowerCase() === filter.toLowerCase()));
          break;
        case 'productItems':
          this.urlproductsData = data[type];
          this.selectedproductItems=this.selectedproductItems.filter(item =>
            data[type].some(filter => item.name.toLowerCase() === filter.toLowerCase()));
          break;
        case 'componentItems':
          this.urlcomponentsData = data[type];
          this.selectedcomponentItems=this.selectedcomponentItems.filter(item =>
            data[type].some(filter => item.name.toLowerCase() === filter.toLowerCase()));
          break;
        case 'versionItems':
          this.urlversionsData = data[type];
          this.selectedversionItems=this.selectedversionItems.filter(item =>
            data[type].some(filter => item.name.toLowerCase() === filter.toLowerCase()));
          break;
        case 'docTypeItems':
          this.urldocumentTypeData = data[type];
          this.selecteddocTypeItems=this.selecteddocTypeItems.filter(item =>
            data[type].some(filter => item.name.toLowerCase() === filter.toLowerCase()));
          break;
        case 'audienceItems':
          this.urlaudiencesetData = data[type];
          this.selectedaudienceItems=this.selectedaudienceItems.filter(item =>
            data[type].some(filter => item.name.toLowerCase() === filter.toLowerCase()));
          break;
        default:
          console.log('Invalid type');
          return;
      }
      this.queryParams.searchInput=this.searchInput;
      this.queryParams.inTitleOnly=this.searchInTitle;
      this.queryParams.productLine=encodeURIComponent(this.selectedproductLines.map(item => item.name));
      this.queryParams.products=encodeURIComponent(this.selectedproductItems.map(item => item.name));
      this.queryParams.components=encodeURIComponent(this.selectedcomponentItems.map(item => item.name));
      this.queryParams.versions=encodeURIComponent(this.selectedversionItems.map(item => item.name));
      this.queryParams.documentType=encodeURIComponent(this.selecteddocTypeItems.map(item => item.name));
      if(this.audienceTypeInternal){
        this.queryParams.audienceset=encodeURIComponent(this.selectedaudienceItems.map(item => item.name.toLowerCase()));
      }
    }, true);


    this.eventBusService.on('cancelFilterApply', (data) => {
   this.mainResetFilter(data)
    },true);
   }

   private mapSelectedData(selectedData: any, key: string): any[] {
    return selectedData?.[key]?.length ? selectedData[key].map(item => item.name) : [];
  }

  toggleModal() {
    this.isFilterApplied=true;
    this.ismodalOpen = !this.ismodalOpen;
    this.modalOpenChange.emit(this.ismodalOpen);
    // this.eventBusService.emit('cancelFilter', this.mainFilteredData);
    this.eventBusService.emit('cancelOutsideFilterApply',true)
  }

  setLineFilter(){
    setTimeout(() => {
      if (this.filterDiv && this.filterDiv.nativeElement) {
        const element = this.filterDiv.nativeElement;
        element.scrollTop = element.scrollHeight - element.clientHeight;
        console.log(`Scroll set to max position: ${element.scrollTop}`);
      }
    }, CONSTANTS.TIMEOUT.filterLoadDelay); // Delay to ensure content is loaded

  }


  getProductLines($event){
    this.selectedproductLines=this.getSelectedItem($event,'productLines');
    this.setParams();
  }

  getProducts($event){
    this.selectedproductItems=this.getSelectedItem($event,'product');
    this.setParams();
  }

  getComponents($event){
    this.selectedcomponentItems=this.getSelectedItem($event,'component');
    this.setParams();
  }
  getVersions($event){
    this.selectedversionItems=this.getSelectedItem($event,'version');
    this.setParams();
  }

  getDocType($event){
    this.selecteddocTypeItems=this.getSelectedItem($event,'docType');
    this.setParams();
  }

  getAudience($event){
    this.selectedaudienceItems=this.getSelectedItem($event,'audience');
    this.setParams();
  }

    clearAllSelections(type='clear'){
    let pData=['productLine','products','components','versions','documentType','audienceset'];
    pData.forEach((element) => {
    this['url' + element + 'Data'] = [];
    });
    this.eventBusService.emit('clearAll');
    this.selectedproductLines=[];
    this.selectedproductItems = [];
    this.selectedcomponentItems = [];
    this.selectedversionItems = [];
    this.selectedaudienceItems = [];
    this.selecteddocTypeItems=[];
    this.queryParams=[];
    this.resetFilter(type);
  }

  filterSearchApi(type='clear'){
    let pData=['productLine','products','components','versions','documentType','audienceset'];
    pData.forEach((element) => {
    this['url' + element + 'Data'] = [];
    });
    this.eventBusService.emit('clearAll');
    this.selectedproductLines=[];
    this.mainFilteredData=[];
    this.selectedproductItems = [];
    this.selectedcomponentItems = [];
    this.selectedversionItems = [];
    this.selectedaudienceItems = [];
    this.selecteddocTypeItems=[];
    this.queryParams=[];
    this.resetFilter(type);
  }

  getSelectedItem($event, type: string) {
    let itemsArray;
    switch (type) {
      case 'productLines':
        itemsArray = this.productLines;
        break;
      case 'product':
        itemsArray = this.productItems;
        break;
      case 'component':
        itemsArray = this.componentItems;
        break;
      case 'version':
        itemsArray = this.versionItems;
        break;
      case 'docType':
        itemsArray = this.docTypeItems;
        break;
      case 'audience':
        itemsArray = this.audienceItems;
        break;
      default:
        console.log('Invalid type');
        return;
    }
    const results = itemsArray?.filter(item => $event?.includes(item.name));
    return results;
  }

  getparmsValue(params,type: string) {
    const decodedValue = decodeURIComponent(params[type] || '');
    const arrayValue = this.convertToArray(decodedValue);
    this['url' + type + 'Data'] = arrayValue.length ? arrayValue : [];
    }

    setParams() {
      this.fetchFilterData(
        this.selectedproductLines?.map((item) => item.name),
        this.selectedproductItems?.map((item) => item.name),
        this.selectedcomponentItems?.map((item) => item.name),
        this.selectedversionItems?.map((item) => item.name),
        this.selecteddocTypeItems?.map((item) => item.name),
        this.selectedaudienceItems?.map((item) => item.name.toLowerCase()),
        this.selectedversionItems?.map((item) => item.item)
      );
    }

  fetchFilterData(productLine:any='',products: any='', components: any='', versions: any='',documentType:any='',audienceset:any='',versionType:any='') {

    this.queryParams = {
      productLine: encodeURIComponent(productLine),
      products: encodeURIComponent(products),
      components: encodeURIComponent(components),
      versions: encodeURIComponent(versions),
      versionType: encodeURIComponent(versionType),
      documentType: encodeURIComponent(documentType),
      audienceset: encodeURIComponent(audienceset),
      searchInput:this.searchInput,
      inTitleOnly:this.searchInTitle

    };
  }

  applyFilter(){
    this.eventBusService.emit(CONSTANTS.EVENTS.PAGE_LOADER, true);
    this.eventBusService.emit('clearDocumentShare',true);
    if(this.queryParams?.versions && this.queryParams?.versions?.length>0 && this.queryParams?.versionType){
     this.verSionParam=this.queryParams?.versionType;
    }
    if(this.queryParams){
    this.queryParams.searchInput=this.searchInput;
    this.queryParams.inTitleOnly=this.searchInTitle;
    if(this.queryParams?.versions && this.queryParams?.versions?.length){
       this.queryParams.versionType=this.verSionParam;
    }
    }
    if(this.queryParams){
      this.queryParams.timestamp = Date.now();
    }
    let ev=this.queryParams;

    this.router.navigate(['/searchresults'], { queryParams: ev });

  }

  ngOnInit(): void {
    if(localStorage.getItem(CONSTANTS.STORAGE.userEligibility) && localStorage.getItem(CONSTANTS.STORAGE.userEligibility)=='true'){
    this.queryParamsSubscription =this.route.queryParams.subscribe(params => {
      this.modalOpenChange.emit(false);
      if(params.productLine || params.products || params.versions || params.components || params.docType || params.audienceset){
      this.paramData.forEach((element) => {
      if(params[element]){
        this.getparmsValue(params,element);
      }


      });
      }
    });
   }
  }

  getFilterData(){
    let productLineObject={"productLine": [],"products": [],"versions": [],
    "components": [],"searchKey": "","docType":[],"inTitleOnly":false,"audience": [],"size":"10","page":"0"}
      this.searchdocumentlistService
    .getdocumentList(productLineObject,'FilterSearch')
    .pipe(
      tap(data => {
        const documentResponse = data?.searchDocumentList?.categoryFilterDocumentResponse;
        const results =documentResponse?.facets;
        const resultSet=results?.field;
        this.productItems=resultSet?.Prdct?.counts;
        this.componentItems=resultSet?.Cmpnt.counts;
        this.versionItems=resultSet?.FVN?.counts;
        this.versionItems= this.versionSorting(this.versionItems)
        this.docTypeItems=resultSet?.DocTyp?.counts;
        this.audienceItems=resultSet?.PermsSts?.counts;
        this.productLines=resultSet?.PL?.counts;
        if(this.setFilterSubscription){
          this.setFilterSubscription.unsubscribe();
        }
      }),
      catchError(error => {
        return of(null); // Return a fallback value or empty observable
      })
    )
    .subscribe();
  }
  convertToArray(param: string): string[] {
    return param.split(',').map(name => name.trim());
  }

  compareWithFn(item1: { name: string }, item2: { name: string }) {
    return item1 && item2 ? item1.name === item2.name : item1 === item2;
  }

 mainResetFilter(id) {
    if (id != 'clear') {
      this.isFilterApplied = true;
    }
    if (id == 'clear') {
      this.searchInput = '';
    }
    this.isApplied = true;
    let productLineObject;
    switch(id.value){
      case 'productLineId':
        this.selectedproductLines = id.selectedItemCode;
        this.queryParams.productLine = encodeURIComponent(
            this.selectedproductLines.map((item) => item.name)
          );
        break;
        case 'productId':
          this.selectedproductItems = id.selectedItemCode;
          this.queryParams.products = encodeURIComponent(
            this.selectedproductItems.map((item) => item.name)
          );
          break;
          case 'versionId':
        this.selectedversionItems = id.selectedItemCode;
        this.queryParams.versions = encodeURIComponent(
          this.selectedversionItems.map((item) => item.name)
        );
        break;
        case 'componentId':
        this.selectedcomponentItems = id.selectedItemCode;
        this.queryParams.components = encodeURIComponent(
          this.selectedcomponentItems.map((item) => item.name)
        );
        break;
        case 'docTypeId':
        this.selecteddocTypeItems = id.selectedItemCode;
        this.queryParams.documentType = encodeURIComponent(
          this.selecteddocTypeItems.map((item) => item.name)
        );
        break;
        case 'audienceId':
          this.selectedaudienceItems = id.selectedItemCode;
          this.queryParams.audienceset = encodeURIComponent(
            this.selectedaudienceItems.map((item) => item.name)
          );
          break;


    }

    productLineObject = {
      productLine: this.selectedproductLines?.map((item) => item.name),
      products: this.selectedproductItems?.map((item) => item.name),
      versions: this.selectedversionItems?.map((item) => item.name),
      components: this.selectedcomponentItems?.map((item) => item.name),
      docType: this.selecteddocTypeItems?.map((item) => item.name),
      searchKey: this.searchInput,
      audience: this.selectedaudienceItems?.map((item) =>
        item.name.toLowerCase()
      )
        ? this.selectedaudienceItems?.map((item) => item.name.toLowerCase())
        : [],
    };
    if(id?.isSilentEvent){
      this.fetchData(productLineObject, 'productLine');
    }else{
      this.isFilterApplied = false
    }

  }


  resetFilter(id){
    this.isAppliedFilter = true;
    if(id!='clear'){
      this.isFilterApplied=true;
    }
    if(id=='clear'){
      this.searchInput='';
    }
    this.isApplied=true;
    let productLineObject;
    productLineObject={"productLine": this.selectedproductLines.map(item => item.name),"products": this.selectedproductItems.map(item => item.name),"versions": this.selectedversionItems.map(item => item.name),"components": this.selectedcomponentItems.map(item => item.name),"docType": this.selecteddocTypeItems.map(item => item.name),"searchKey": this.searchInput,"audience": this.selectedaudienceItems.map(item => item.name.toLowerCase())?this.selectedaudienceItems.map(item => item.name.toLowerCase()):[]}
    this.fetchData(productLineObject,'productLine');
  }

  fetchData(productLineObject,selectedId){
    this.searchdocumentlistService
    .getdocumentList(productLineObject,'FilterSearch')
    .pipe(
      tap(data => {
        const documentResponse = data?.searchDocumentList?.categoryFilterDocumentResponse;
        const results =documentResponse?.facets;
        const resultSet=results?.field;
       let swData=this;
       if(resultSet){
        swData.isFilterApplied=false;
        swData.productItems=resultSet?.Prdct?.counts;
        swData.componentItems=resultSet?.Cmpnt.counts;
        swData.versionItems=resultSet?.FVN?.counts;
        swData.versionItems= this.versionSorting(this.versionItems)
        swData.docTypeItems=resultSet?.DocTyp?.counts;
        swData.audienceItems=resultSet?.PermsSts?.counts;
        swData.productLines=resultSet?.PL?.counts;

      if(swData.productLines){
      const filteredProductLines = swData.productLines.filter(item =>
        swData.selectedproductLines.some(filter => item.name.toLowerCase() === filter.name.toLowerCase())
      );
      swData.selectedproductLines = filteredProductLines;
      swData.urlproductLineData = filteredProductLines.map(item => item.name);

      }
      if(swData.productItems){
      const filteredProductItems =swData.productItems.filter(item =>
        swData.selectedproductItems.some(filter => item.name.toLowerCase() === filter.name.toLowerCase())
      );
      swData.selectedproductItems = filteredProductItems;
      swData.urlproductsData = filteredProductItems.map(item => item.name);
      }
      if(swData.componentItems){
      const filteredComponentItems =swData.componentItems.filter(item =>
        swData.selectedcomponentItems.some(filter => item.name.toLowerCase() === filter.name.toLowerCase())
      );
      swData.selectedcomponentItems = filteredComponentItems;
      swData.urlcomponentsData= filteredComponentItems.map(item => item.name);
      }
      if(swData.docTypeItems){
      const filtereddocTypeItems =swData.docTypeItems.filter(item =>
        swData.selecteddocTypeItems.some(filter => item.name.toLowerCase() === filter.name.toLowerCase())
      );
      swData.selecteddocTypeItems =filtereddocTypeItems;
      swData.urldocumentTypeData=filtereddocTypeItems.map(item => item.name);
      }
      if(swData.audienceItems){
      const filteredaudienceItems =swData.audienceItems.filter(item =>
        swData.selectedaudienceItems.some(filter => item.name.toLowerCase() === filter.name.toLowerCase())
      );
      swData.selectedaudienceItems =filteredaudienceItems;
      swData.urlaudiencesetData=filteredaudienceItems.map(item => item.name);
      }
      if(swData.versionItems){
        const filteredversionItems =swData.versionItems.filter(item =>
          swData.selectedversionItems.some(filter => item.name.toLowerCase() === filter.name.toLowerCase())
        );
        swData.selectedversionItems =filteredversionItems;
        swData.urlversionsData=filteredversionItems.map(item => item.name);
      }
      if(selectedId=='isCancelOutside'){
        this.isAppliedFilter=false;
        let productLineObject;
        productLineObject={"productLine": this.mainFilteredData?.productLine,"products": this.mainFilteredData?.products,"versions": this.mainFilteredData?.versions,"components": this.mainFilteredData?.components,"docType": this.mainFilteredData?.docType,"searchKey": this.mainFilteredData?.searchKey,"audience": this.mainFilteredData?.audience}
          this.setCancelData(productLineObject);
      }
    }else{
      this.isFilterApplied=false;
    }

      }),
      catchError(error => {
        this.isFilterApplied=false;
        return of(null); // Return a fallback value or empty observable
      })
    )
    .subscribe();
  }



  versionSorting(data: any) {
    const processedData = data?.map((item: any) => {
      const nameObj = JSON.parse(item.name);
      const { VR, SP, PB } = nameObj;
      let processedName: string;
      let processedValue: string;

      if (PB) {
        processedName = PB;
        processedValue = SP ? `${VR}|${SP}|${PB}` : `${VR}||${PB}`;
      } else if (SP) {
        processedName = `${VR}.${SP}`;
        processedValue = `${VR}|${SP}`;
      } else {
        processedName = VR;
        processedValue = VR;
      }

      return {
        count: item.count,
        name: processedName,
        versionSelected: processedValue,
        item: item.name,
      };
    });

    if (processedData) {
      return processedData.sort((a: any, b: any) => {
        const isNumericVersion = (version: string) => /^\d+(\.\d+)*$/.test(version);

        const parseVersion = (version: string) => {
          const regex = /([A-Za-z]*)(\d+(?:\.\d+)*)/;
          const match = version.match(regex);
          if (!match) return [version]; // Return as-is if no match
          const [, prefix, numeric] = match;
          const numericParts = numeric.split('.').map(part => Number(part));
          return [prefix, ...numericParts];
        };

        const aIsNumeric = isNumericVersion(a.name);
        const bIsNumeric = isNumericVersion(b.name);

        // Numeric versions take precedence
        if (aIsNumeric && !bIsNumeric) return -1;
        if (!aIsNumeric && bIsNumeric) return 1;

        // If both are numeric or both are alphanumeric, parse and compare
        const firstParts = parseVersion(a.name);
        const secondParts = parseVersion(b.name);

        for (let i = 0; i < Math.max(firstParts.length, secondParts.length); i++) {
          const partA = firstParts[i] !== undefined ? firstParts[i] : 0;
          const partB = secondParts[i] !== undefined ? secondParts[i] : 0;

          if (typeof partA === 'number' && typeof partB === 'number') {
            if (partA !== partB) return partB - partA; // Descending numeric order
          } else if (typeof partA === 'string' && typeof partB === 'string') {
            if (partA < partB) return 1; // Descending lexicographical order
            if (partA > partB) return -1;
          } else {
            return typeof partA === 'number' ? -1 : 1; // Numbers take precedence
          }
        }
        return 0;
      });
    }

    return [];
  }


  // Common function to sort an array by a specified field, only if the value is not null
  sortArrayByField(array: any[], field: string): any[] {
    if (!array) {
      return []; // Return an empty array if the input array is null or undefined
    }
    return array.sort((a, b) => {
      if (a[field] === null || b[field] === null) {
        return 0; // Do not change order if either value is null
      }
      const valueA = a[field]?.toLowerCase();
      const valueB = b[field]?.toLowerCase();
      const comparison = valueA?.localeCompare(valueB);
      if (comparison !== 0) {
        return comparison;
      }
      // If case-insensitive comparison is equal, use case-sensitive comparison
      return a[field]?.localeCompare(b[field]);
    });
  }

  setCancelData(cancelData:any){
    this.urlproductLineData= [];
    this.urlproductsData = [];
    this.urlcomponentsData=[];
    this.urlversionsData=[];
    this.urldocumentTypeData=[];
    this.urlaudiencesetData=[];
    this.isFilterApplied=false;
    this.selectedproductLines =  [];
    this.selectedproductItems =  [];
    this.selectedcomponentItems = [];
    this.selectedversionItems = [];
    this.selecteddocTypeItems = [];
    this.selectedaudienceItems =  [];


    this.urlproductLineData= cancelData?.productLine || [];
    this.urlproductsData = cancelData?.products  || [];
    this.urlcomponentsData=cancelData?.components  || [];
    this.urlversionsData=cancelData?.versions  || [];
    this.urldocumentTypeData=cancelData?.docType  || [];
    this.urlaudiencesetData=cancelData?.audience  || [];

    this.selectedproductLines = cancelData?.productLine || [];
    this.selectedproductItems = cancelData?.products || [];
    this.selectedcomponentItems = cancelData?.components || [];
    this.selectedversionItems = cancelData?.versions || [];
    this.selecteddocTypeItems = cancelData?.docType || [];
    this.selectedaudienceItems = cancelData?.audience || [];

    this.queryParams =  {"productLine": encodeURIComponent(this.selectedproductLines),"products": encodeURIComponent(this.selectedproductItems),"versions": encodeURIComponent(this.selectedversionItems),
      "components": encodeURIComponent(this.selectedcomponentItems),
      "documentType":encodeURIComponent(this.selecteddocTypeItems),"searchKey":this.searchInput,"audienceset": encodeURIComponent(this.selectedaudienceItems),"size":"1","page":"0",'inTitleOnly':this.searchInTitle}


    if(this.router.url==='/productline'){
    this.userService.currentProductLine.subscribe(productLine => {
        this.urlproductLineData=productLine?.name ? [productLine?.name] : [];
        this.selectedproductLines = productLine?.name ? [productLine?.name] : [];

    });
   }

   if(this.router.url==='/'){
    this.queryParams = [];
    this.urlproductLineData= [];
    this.urlproductsData = [];
    this.urlcomponentsData=[];
    this.urlversionsData=[];
    this.urldocumentTypeData=[];
    this.urlaudiencesetData=[];
    this.isFilterApplied=false;
    this.selectedproductLines =  [];
    this.selectedproductItems =  [];
    this.selectedcomponentItems = [];
    this.selectedversionItems = [];
    this.selecteddocTypeItems = [];
    this.selectedaudienceItems =  [];
   }
  }


  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
    if(this.setFilterSubscription){
      this.setFilterSubscription.unsubscribe();
    }
    if(this.routerSubscription){
      this.routerSubscription.unsubscribe();
    }
    if(this.productLineSubscription){
      this.productLineSubscription.unsubscribe();
    }
    if(this.filterCloseData){
      this.filterCloseData.unsubscribe();
    }
  }

}


<div class="course-card box" id="{{cardIndex}}">
    <div class="box-content">
        <div class="d-flex justify-content-between">

            <div><input class="checkmark"
                    [checked]="isdocumentDetail? pitemDetails.isChecked : pitemDetails.fields.isChecked"
                    [disabled]="isdocumentDetail? pitemDetails.isDisabled!=undefined && pitemDetails.isDisabled : pitemDetails.fields.isDisabled && shareCount==count"
                    (change)="onCheckboxChange ($event,pitemDetails.fields)" type="checkbox" id="prdline" name="pdline"
                    value="Bike">
                <label for="prdline"></label>
            </div>
            <div class="d-flex">
                <div class="openOptions" (click)="openOptions($event,pitemDetails.fields,cardIndex);"><img class="openOptionsSvg"
                        src="{{assetsPath}}options.svg" alt="menu-icon" /></div>
            </div>
        </div>
        <div class="course-name" (click)="opendocument(pitemDetails.fields.UnqI, pitemDetails.fields.FileT,this.productLine);">
            <div class="course-category">
              <div *ngIf="showPopup" class="courseDetails">
                {{ pitemDetails?.fields?.KIN[0] }}
              </div>

              <h3  *ngIf="!isdocumentDetail">
                {{ pitemDetails?.fields?.KIN[0] | slice:0:65 }}
                <span *ngIf="pitemDetails?.fields?.KIN[0].length > 65"
                      (mouseenter)="showPopup = true"
                      (mouseleave)="showPopup = false">...</span>
              </h3>

                 <h3 *ngIf="isdocumentDetail  && !searchInput" innerHTML="{{pitemDetails?.fields?.KIN}}"></h3>
                 <h3 *ngIf="isdocumentDetail && searchInput" innerHTML="{{ pitemDetails?.highlights?.KIN ? pitemDetails?.highlights?.KIN[0].value : pitemDetails?.fields?.KIN}}"></h3>

                <div>
                    <div *ngIf="isdocumentDetail && pitemDetails?.fields?.SNPT && !searchInput"
                        innerHTML="{{ pitemDetails?.fields?.SNPT}}"></div>

                    <div *ngIf="isdocumentDetail && pitemDetails?.highlights?.SNPT && searchInput">
                        <!--<p innerHTML="{{getHighlights(pitemDetails?.highlights?.SNPT)}}"></p>-->
                        <p *ngIf="pitemDetails.fields.FileT.toLowerCase() == 'html'" innerHTML="{{getHighlights(pitemDetails?.highlights?.SNPT)}}"
                        (click)="open(pitemDetails?.fields?.VSK,pitemDetails.fields.UnqI); $event.stopPropagation()"></p>
                        <p *ngIf="pitemDetails.fields.FileT.toLowerCase() != 'html'" innerHTML="{{getHighlights(pitemDetails?.highlights?.SNPT)}}"></p>
                        <span class="seeMore" *ngIf="isdocumentDetail &&  pitemDetails?.highlights?.SNPT &&
                    pitemDetails?.groupedDocs && searchInput && !seeMore && pitemDetails?.fields?.FileT.toLowerCase() == 'html'"
                            (click)="seeMoreDocuments(pitemDetails.fields.UnqI, searchInput, pitemDetails); $event.stopPropagation()">See
                            more</span>
                    </div>
                    <div class="seeMoreDiv" *ngIf="isdocumentDetail && searchInput && pitemDetails?.fields?.SNPT && pitemDetails?.innerSearch &&
                    pitemDetails?.innerSearch.length > 0 && seeMore">


                        <div *ngFor="let group_data of pitemDetails?.innerSearch;"
                            (click)="open(group_data.fields.VSK,pitemDetails.fields.UnqI); $event.stopPropagation()">
                            <hr *ngIf="group_data?.highlights?.SNPT" />
                            <p *ngIf="group_data?.highlights?.SNPT" innerHTML="{{getHighlights(group_data?.highlights?.SNPT)}}"></p>
                        </div>
                        <span class="seeMore" *ngIf="seeMore" (click)="seeLessDocuments(); $event.stopPropagation()">See
                            less</span>
                    </div>

                <div>
                  <div class="tree" *ngIf="isdocumentDetail && pitemDetails.fields.APrdctTre && isProductTree">
                    {{ removeNA(pitemDetails.fields.APrdctTre[0]) }}
                    <div *ngIf="pitemDetails.fields.APrdctTre.length > 1" class="filter-count">
                      {{pitemDetails.fields.APrdctTre.length}}
                    </div>
                    <div class="product-tree" *ngIf="isProductTree && pitemDetails.fields.APrdctTre.length > 1">
                      <p *ngFor="let tree_data of pitemDetails.fields.APrdctTre;">
                        {{ removeNA(tree_data) }}
                      </p>
                    </div>
                  </div>
                    <p><span>Version:</span>  {{ formatData(pitemDetails.fields?.FVN) }}</p>
                    <p *ngIf="!isdocumentDetail"><span>Product:</span> {{ formatData(pitemDetails.fields?.Prdct) }}</p>
                    <p><span>Published:</span> {{pitemDetails.fields?.LstUpdt|dateFormat}} | <img
                            [src]="getFileIcon(pitemDetails.fields.FileT)" alt="file-icon" /><span
                            class="details">{{pitemDetails.fields.FileT}}</span>
                        <span class="divider" *ngIf="pitemDetails?.fields?.PermsSts!='external' &&  audienceTypeInternal">|</span> <img
                            *ngIf="pitemDetails?.fields?.PermsSts!='external' && audienceTypeInternal" src="/assets/icons/internal.svg"
                            alt="file-icon" /><span *ngIf="pitemDetails?.fields?.PermsSts!='external' && audienceTypeInternal"
                            class="details">{{pitemDetails?.fields?.PermsSts!='external' && audienceTypeInternal?
                            capitalizeFirstLetter(pitemDetails?.fields?.PermsSts) : ''}}</span>
                    </p>
                </div>

                </div>
            </div>
        </div>

        <app-amdocs-loader *ngIf="loader" class="loader"></app-amdocs-loader>

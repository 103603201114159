    import {Injectable} from '@angular/core';
    import {AmdocsAppsyncClientService} from 'amdocs-core-package';
    import {Observable, of} from 'rxjs';
    import {catchError, mergeMap} from 'rxjs/operators';
    @Injectable()
    export class SearchDocumentListService {
    constructor( private appSyncClient: AmdocsAppsyncClientService) {
    }
    getdocumentList(filterCriteria,queryName): Observable<any> {
        let documentListQName = queryName;
        const documentListQuery = `query ${documentListQName}($filterCriteria: SearchCriteriaInput!) {
    searchDocumentList(filterCriteria: $filterCriteria) {

        categoryFilterDocumentResponse {

        facets {

            field {

            Cmpnt {

                label

                counts {

                count

                name

                }

            }

            PermsSts{

                label

                counts {

                count

                name

                }

            }

            PL{

                label

                counts {

                count

                name

                }

            }

            Prdct {

                label

                counts {

                count

                name

                }

            }

            DocTyp {

                label

                counts {

                count

                name

                }

            }

            DocCategory {

                label

                counts {

                count

                name

                }

            }

            FVN {

                label

                counts {

                count

                name

                }

            }

            VR {

                label

                counts {

                count

                name

                }

            }

            }

        }

        results {

            groupField

            list {

            hits

            maxScore

            docs {

                collection

                groupValue

                groupedDocs{
              hits
               docs {
                             collection
                             type
                          id
                             fields {
                               APrdctTre
                               DocCert
                               DocDt
                               DocCategory
                               DocNum
                               DocTyp
                               FVN
                               KII
                               KIN
                               PermsSts
                               PL
                               LstUpdt
                               KIT
                               Prdct
                               SNPT
                               SPUrl
                               SRC
                               UnqI
                               VR
                               VSK
                               FileT
            }
                               highlights{
              KIN{
                value
              }
              SNPT{
                value
              }
            }
          }
            }


                fields {

                APrdctTre

                DocCert

                DocDt

                DocNum

                DocTyp

                FVN

                KII

                KIN

                PermsSts

                PL

                LstUpdt

                KIT

                Prdct

                SNPT

                SPUrl

                SRC

                UnqI

                VR

                VSK

                FileT

                DocCategory

                }
                highlights{
              KIN{
                value
              }
              SNPT{
                value
              }
            }

            }

            }

        }

        }

        errorMessage

        expires

    }

    }


    `;
        const paramsvalues = {
            filterCriteria
        };
        return this.appSyncClient.call(documentListQuery, documentListQName, paramsvalues).pipe(
            mergeMap( (res: any): Observable<any> => {
            return of(res);
            }),
            catchError( err => {
            console.log(err);
            return of({error: err});
            })
        );
    }
    }

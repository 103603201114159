import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { menuModel } from './model/menu.model';
import { AmdocsUserService,AmdocsAuthService } from 'amdocs-core-package';
import { AvatarImgSize } from '../../../shared/avatar/avatar.component';
import jwt_decode from 'jwt-decode';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { UserService } from "../../../service/user.service"
import { finalize } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public menuList : any= [{routerLink: '', isActive: true, linkName: 'Home', type:'inside'}];
  public employeeName: string = '';
  public avatarImgSize = AvatarImgSize;
  public showLoader: boolean = false;
  public showLogoff: boolean = false;
  @ViewChild('outsideClick', { static: true }) outsideClick: ElementRef;

  constructor(private amdocsUserService:AmdocsUserService,private authService:AmdocsAuthService,private eventBus: AmdocsEventBusService,private user: UserService,private router:Router) {
    let formattedToken: any = '';
    if (jwt_decode(this.authService.getToken())) {
      formattedToken = jwt_decode(this.authService.getToken());
      this.employeeName = formattedToken.name ? formattedToken.name : '';
    }

  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.outsideClick && !this.outsideClick.nativeElement.contains(event.target)) {
      this.showLogoff = false; // Perform the necessary action
    }
  }

  ngOnInit(): void {
    this.menuList.push({
      routerLink: environment.headerConfig.supportOne, 
      isActive: false, 
      linkName: 'SupportONE',
      type:'outside'
    })
  }

  getHome(){
    this.router.navigate(['/'], {replaceUrl: true});
  }

  routeToModule(link: string,type:string){
    if(type=='inside'){
    this.menuList.find((x: { routerLink: string; }) => x.routerLink == link).isActive = true;
    this.menuList.find((x: { routerLink: string; })=> x.routerLink !== link).isActive = false;
    this.router.navigate([link],{replaceUrl: true});
    }else{
      window.open(link);
    }
   }

   getUserShortName(name): string {
    return this.amdocsUserService.getUserShortName(name);
  }

  openLink(link: any, type: any) {
    if (type == 'inside') {
      this.router.navigate(['/'],{replaceUrl: true});
    } else {
      window.open(link);
    }
  }

  showLogoutDrwaer(): void{
    this.showLogoff = !this.showLogoff;
  }


  onLogout(): void {
    this.showLoader = true;
    sessionStorage.clear();
    this.authService
    .logout()
    .pipe(
      finalize(() => {
        
      })
      )
      .subscribe(resp =>{
        this.showLoader = false;
      });
  }

  openNewLink(){
    this.router.navigate(['/'], {replaceUrl: true});
  }

  get name(): string {
    return this.user.getName();
  }

  get empId(): string {
    return this.user.getEmpId();
  }
}

<div class="terms-wrapper">
    <span (click)="closeTermsModal()" class="close">&#x2716;</span>
    <h1 class="heading-def">Terms and Conditions</h1>
    <div class="content-section">
        <div class="terms-condition">
        <div [innerHtml]="termsData"></div>
        <a class="copyrights" href="https://www.amdocs.com/about/copyright-notice" target="_blank">https://www.amdocs.com/about/copyright-notice</a>
    </div>

    </div>
</div>
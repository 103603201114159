<div class="container-fluid no-gutters px-0" #titlesection data-qa="banner-meta">

    <div class="card w-100 banner-page" id="banner-page">
      <nav aria-label="breadcrumb" class="flex space-between">
        <div>
          <ol class="breadcrumb" data-qa="breadcrumb">
            <li class="breadcrumb-item"><a (click)="navigatetoHome()" routerLink="/">Home</a></li>
            <li (click)="navigateToProductLine()" class="breadcrumb-item"><a>{{productLineTitle}}</a> </li>
            <li class="breadcrumb-item active" aria-current="page">{{docsTitle['KIN']}}</li>
          </ol>
          <div class="hstack gap-2 ms-5">
            <div class="vstack gap-2 mrgn-15 mb-3">
              <div class="sub-header">
                {{formattedTitle}}
                <span data-qa="banner-metatitle"
                  *ngIf="this.docsTitle && this.docsTitle['APrdctTre'] && this.docsTitle['APrdctTre'].length > 1">
                  ...
                  <div>{{showMoreProductLines}}</div>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="allButton">
          <div class="selection-btn">
            <a (click)="download()" data-qa="product-line-download"><img alt="download" src="/assets/icons/downloadSelected.svg">
              <span class="shareText">Download</span>
            </a>
          </div>
          <div class="selection-btn" (click)="shareCourse()">
            <a [href]="mailtoLink" target="_blank">
              <img src="/assets/icons/shareSelected.svg" alt="share" />
              <span class="shareText">Share</span>
            </a>
          </div>
        </div>
      </nav>
      <div class="mainDiv">

    </div>
  </div>
</div>
<app-amdocs-loader *ngIf="isDocumentAPILoading" class="loader"></app-amdocs-loader>




<app-download-failed *ngIf="downloadFailedPopup" [downloadFailed]="downloadFailed" (closeDownloadError)="closeDownloadError()" ></app-download-failed>


<div id="{{cardIndex}}" *ngIf="(openMenu == cardIndex) && (menuId == cardIndex)">
<div id="card-menu-wrapper" class="desktop-open" data-qa="card-menu">
    <div class="container course-options">
        <div class="list">

            <div (click)="download(cardData)" class="list-item" data-qa="download">
                <img src="{{ assetsPath }}download_pl.svg" alt="download" />Download
            </div>
            <div class="list-item" data-qa="share">
                <a [href]="mailtoLink" target="_blank">
                <img src="{{ assetsPath }}share_pl.svg" alt="share" />Share
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="isMobile" class="list-item list-button" (click)="togglemenu()">
        {{ "cardsmenu.close" | amdocsTranslate }}
    </div>
</div>
</div>

<app-download-failed *ngIf="downloadFailedPopup" [downloadFailed]="downloadFailed" (closeDownloadError)="closeDownloadError()" ></app-download-failed>

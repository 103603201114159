import { Component } from '@angular/core';

@Component({
  selector: 'app-quicklinks',
  templateUrl: './quicklinks.component.html',
  styleUrls: ['./quicklinks.component.scss']
})
export class QuicklinksComponent {
  public quickLinks:boolean=false;
  openQuickLinks(){
    this.quickLinks=true;
  }

  setQuickLink(value){
    this.quickLinks=value;
  }

}

import {Injectable} from '@angular/core';
import {AmdocsAppsyncClientService} from 'amdocs-core-package';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';


@Injectable()
export class InitService {
  private responseProductMeteData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor( private appSyncClient: AmdocsAppsyncClientService) {
  } 

  getProductLineMetaData(apsSupportOneRequest): Observable<any> {
    const initQuery = `query GetProductLineMetaData($apsSupportOneRequest: ApsSupportOneRequestInput!) {
      getProductLineMetaData(apsSupportOneRequest: $apsSupportOneRequest) {
        Classic {
                Category
                values {
                  SysID
                  isMarkFavourite
                  name
                }
              }
              Rapid {
                Category
                values {
                  SysID
                  isMarkFavourite
                  name
                }
              }
              params {
                Dac_SessionID
                ErrorDesc
                IsEligible
                SO_SessionID
                UPN
                User_ID
              }
    }
    }
  `;
    const paramsvalues = {apsSupportOneRequest};
    return this.appSyncClient.call(initQuery, 'GetProductLineMetaData', paramsvalues).pipe(
        mergeMap( (res: any): Observable<any> => {
          this.responseProductMeteData.next(res);
          return of(res);
        }),
        catchError( err => {
          console.log(err);
          return of({error: err});
        })
    );
  }

  getProductLineResponseData(): Observable<any> {
    return this.responseProductMeteData.asObservable();
  }


}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AmdocsEventBusService } from 'amdocs-core-package';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit,OnDestroy {

  constructor(private router:Router, private location: Location,private eventBus: AmdocsEventBusService){
  }

  ngOnInit(): void {
    this.eventBus.emit('isfixedFooter',true);
  }

  openHomePage(){
    this.router.navigate(['/']);
  }
  
  openPreviousPage(){
    this.location.back();
  }
  ngOnDestroy(): void {
    this.eventBus.emit('isfixedFooter',false);
  }
}

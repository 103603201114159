// eligibility.guard.ts
import { Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EligibilityService } from '../service/eligibility.service';
import { UserService } from '../service/user.service';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EligibilityGuard {
  constructor(private eligibilityService: EligibilityService, private router: Router,private userService:UserService,private eventBus:AmdocsEventBusService) {}

  canActivate: CanActivateFn = (): Observable<boolean> | boolean => {
    console.log(localStorage.getItem('isFirstLogin'));
    let isLocalServer=environment.dacSessionId;
    if (this.userService.getEmail() && !localStorage.getItem('isFirstLogin') && !isLocalServer) {
      console.log('In eligibility');  
      this.eventBus.emit('pageloader', true);
        let userData= {
            "SO_Session_ID": sessionStorage.getItem('SOSessionID')?sessionStorage.getItem('SOSessionID'):'',
            "User_ID": this.userService.getEmail(),
            "IP_Details": "",
            "Emp_ID": this.userService.getEmployeeCode(),
            "DaC_Session_ID": ""
          }
        return this.eligibilityService.getEligibility(userData).pipe(
          map(eligible => {
            if(eligible.getEligibilityRuleFromAPS.params['IsEligible']==true){
              const filterObject = eligible.getEligibilityRuleFromAPS.queryDefinition.filter.find(f => f.field === 'PermsSts');
              const filterValues = filterObject ? filterObject.values : [];
              const isInternal = filterValues.includes('internal')?'true':'false';
              localStorage.setItem('isInternal',isInternal);
              setTimeout(() => {
                this.eventBus.emit('userEligible',true);
                this.eventBus.emit('pageloader', false);
                if(localStorage.getItem('isEligibleEvent')){
                  localStorage.removeItem('isEligibleEvent');
                }
                this.eventBus.emit('isEligible','true');
                localStorage.setItem('docsel','true');
                return true;
              }, 100);
            }else {
              setTimeout(() => {
                this.eventBus.emit('isEligible','false');
                this.eventBus.emit('pageloader', false);
              }, 1000);
              console.log('elcall')
              this.router.navigate(['/access-denied']);
              return true;
            }
          }),
          catchError(() => {
            console.log('errorcall')
            this.router.navigate(['/access-denied']);
            return of(false);
          })
        );
      }else{
        localStorage.removeItem('isFirstLogin');
        return of(true);
      }
  };
}
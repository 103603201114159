<nav class="navbar navbar-expand-lg navbarCustom navbar-default navbar-fixed-top sticky-top" data-qa="nav-section">
  <div class="container-fluid custom-wd">
    <div class="ms-5">
      <img  (click)="openNewLink()" alt="logo" src="/assets/icons/amdocsLogo.svg">
    </div>
    <div class="navbar-brand p-2 text-light me-5 title" (click)="openNewLink()">
      Product Documentation
    </div>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 textWidth">
        <li class="nav-item p-2" *ngFor="let route of menuList">
          <a class="nav-link text-light horiz-menu-tab"  [routerLinkActiveOptions]="{exact: true}"  routerLinkActive="active" 
          id="links" (click)="routeToModule(route.routerLink,route.type)"
          >{{route.linkName}}</a>
        </li>
      </ul>
      <span class="hstack gap-2" #outsideClick>
      <div class="avatar-section" (click)="showLogoutDrwaer()">   
        <span class="hstack gap-2  initial-sect">
          <div class="ms-4">
            <div class="initials">
              <app-avatar [size]="avatarImgSize.small"></app-avatar>
          </div>
          </div>
          <div class="text-light">
             {{employeeName}}
          </div>
        </span>
        <div *ngIf="showLogoff" class="logoff" (click)="onLogout()">
          <span><img alt="logout" src="/assets/icons/logout.svg"></span>
          <div
            class="btn-logout" data-qa="logout"
          >
          Log out
        </div>
        </div>
      </div>
   </span>
    
   
  </div>
  </div>
</nav>
<div *ngIf="showLoader">
  <app-amdocs-loader></app-amdocs-loader>
</div>
import { Component , Input , OnChanges , Output , EventEmitter, OnInit} from '@angular/core';
import { AmdocsDeviceService, AmdocsEventBusService } from 'amdocs-core-package';
import { CONSTANTS } from 'src/app/constants';

@Component({
  selector: 'app-pagination',
  templateUrl: './app-pagination.component.html',
  styleUrls: ['./app-pagination.component.scss']
})

export class PaginationComponent implements OnInit,OnChanges {

    @Input() totalRecords = 0;
    @Input() recordsPerPage = 0;
    @Input() itemsDetails:any=[];
    @Input() courseDetails: any ;
    @Input() paginate:any;
    @Output() onPageChange: EventEmitter<number> = new EventEmitter();
    public pages: number [] = [];
    public activePage: number=1;
    public filteredItems:any=[];
    public recordLimitPage=CONSTANTS.RECORDS_LIMIT_PAGE;
    public imageUrl=CONSTANTS.ASSETS_URL;
    public isMobile=this.amdocsMobile.isMobile();

    constructor(private eventBusService:AmdocsEventBusService,public amdocsMobile:AmdocsDeviceService){

    }

    ngOnInit(){
      this.filteredItems=this.courseDetails;
    }

    ngOnChanges(){
      const pageCount = this.getPageCount();
      this.pages = this.getArrayOfPage(pageCount);
     // this.activePage = 1;
     // this.onClickPage(this.paginate);
     this.activePage = this.paginate;
    }

  

    

    private  getPageCount(): number {
      let totalPage = 0;
      if (this.totalRecords > 0 && this.recordsPerPage > 0) {
        const pageCount = this.totalRecords / this.recordsPerPage;
        const roundedPageCount = Math.floor(pageCount);
        totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
      }

      return totalPage;
    }

    private getArrayOfPage(pageCount: number): number [] {
      const pageArray = [];

      if (pageCount > 0) {
          for(let i = 1 ; i <= pageCount ; i++) {
            pageArray.push(i);
          }
      }

      return pageArray;
    }

    onClickPage(pageNumber: number): void {
      //this.onPageChange.emit(pageNumber);
       this.activePage = pageNumber
        this.eventBusService.emit(CONSTANTS.EVENTS.PAGINATOR, pageNumber);
    }
}

<div id="app" class="container-pages paginate">
    <ul class="page" class="{{'page-' + [pages.length]}}" [ngClass]="{'page-mob': isMobile,'desk':!isMobile}">
        <li class="page-btn" (click)="onClickPage(activePage - 1)">
            <img src="../../../../assets/icons/backpagination.svg" alt="arrow-back" />
        </li>
        <ng-container *ngFor="let item of pages">
        <li class="page-numbers" [ngClass]="{'active': activePage === item}"  *ngIf="item < recordLimitPage" (click)="onClickPage(item)"><a  class="page-link" href="javascript:void(0);">{{item}}</a></li>
        <li class="page-numbers" *ngIf="item>=recordLimitPage && item===pages.length"><a  class="page-link" href="javascript:void(0);">...</a></li>
        <li class="page-numbers" [ngClass]="{'active': activePage === item}"  *ngIf="item>=recordLimitPage && item===pages.length" (click)="onClickPage(item)"><a  class="page-link" href="javascript:void(0);">{{pages.length}}</a></li>
       </ng-container>  
        <li class="page-btn" (click)="onClickPage(activePage + 1)">
            <img src="../../../../assets/icons/forwardpagination.svg" alt="arrow-forward" />
        </li>
    </ul>
</div>
import { NgModule ,APP_INITIALIZER} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AmdocsAppInitService, AmdocsAppInitResponse, IAppSyncConfig, IAppGatewayConfig, ILoginGatewayConfig,AmdocsUiModule, AmdocsEventBusService,AmdocsUtilsModule } from 'amdocs-core-package';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './page-layout/header/header/header.component';

import { BannerComponent } from './page-layout/banner/banner/banner.component';
import { PageBannerComponent } from './page-layout/banner/banner/homePage/page-banner/page-banner.component';
import { catchError, finalize, mergeMap } from "rxjs/operators";
import { of, throwError } from "rxjs";
import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { InitService } from './service/init.service';
import { MetaDataService } from './service/metaData.service';
import { UserService } from './service/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShareModule } from './shared/share.module';
import { SessionService } from './service/session.service';
import { EligibilityService } from './service/eligibility.service';
import { NetworkService } from './service/network.service';
import { MarkUnMarkFavouriteProductLine } from './service/markFavourite.service';
import { switchMap } from 'rxjs/operators';
import { AccessDeniedComponent } from './feature/accessdenied/accessDenied.component';
import { DownloadDocumentService } from './service/downloadDocument.service';

export function initializeApp(
  appInitService: AmdocsAppInitService,
  router: Router,
  eventBusService:AmdocsEventBusService,
  sessionService:SessionService,
  eligibilityService:EligibilityService,
  userService:UserService,
  initService:InitService,
  markFavouriteProductList:MarkUnMarkFavouriteProductLine

): any {
  const appInit = appInitService;
  const currentUrl  = window.location.href;
    if(currentUrl.indexOf("?") > -1){
    const paramss = currentUrl.split('?'); // Remove the first empty element
    const queryString =paramss[1]
    let params;
    if(paramss[1].includes('&')){
       params = queryString.split('&');
    const queryParams: any = {};

    if(params.length>1){
    params.forEach(param => {
      const [key, value] = param.split('=');
      queryParams[key] = value;

    });
    const docUID = queryParams['DocUID']?queryParams['DocUID']:'';
    const soSessionID = queryParams['SOSessionID']?queryParams['SOSessionID']:'';
    const startPage = queryParams['StartPage']?queryParams['StartPage']:'';
    if(docUID) sessionStorage.setItem('DocUID', docUID);
    if(soSessionID)sessionStorage.setItem('SOSessionID', soSessionID);
    if(startPage)sessionStorage.setItem('StartPage', startPage);
    if(docUID) sessionStorage.setItem('isdocumentCallback','1');

  }
    }
    else{
     const shareId= queryString.split('=')[1];
      if(shareId) sessionStorage.setItem('shareId',shareId);
    }

  }

  eventBusService.on('initLogin', (data: any) => {
    localStorage.setItem('isEligibleEvent','1');
    localStorage.setItem('isFirstLogin','1');
    sessionService.setSessionData()
    .pipe(
      mergeMap((response: any) => {
       let userData= {
            "SO_Session_ID": sessionStorage.getItem('SOSessionID')?sessionStorage.getItem('SOSessionID'):'',
            "User_ID": userService.getEmail(),
            "IP_Details": "",
            "Emp_ID": userService.getEmployeeCode(),
            "DaC_Session_ID": ""

        }
        return eligibilityService.getEligibility(userData);
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    )
    .subscribe({
      next: (response: any) => {
         if(response){
          let isElig='false';
          if(response && response.getEligibilityRuleFromAPS!=null && response.getEligibilityRuleFromAPS.params['IsEligible']==true){
            isElig='true';
          const filterObject = response.getEligibilityRuleFromAPS.queryDefinition.filter.find(f => f.field === 'PermsSts');
          const filterValues = filterObject ? filterObject.values : [];
          const isInternal = filterValues.includes('internal')?'true':'false';
          localStorage.setItem('isInternal',isInternal);
          }else{
            isElig='false';
          }
          localStorage.setItem('docsel',isElig);
          setTimeout(() => {
            // Emit an event
            eventBusService.emit('isEligible',isElig);
            let isdoc = sessionStorage.getItem("isdocumentCallback");
            if (!isdoc && isElig=='true') {
              initService.getProductLineMetaData({
                User_ID: userService.getEmail(),
                Emp_ID: userService.getEmployeeCode(),
                SO_Session_ID: "",
                IP_Details: "",
                DaC_Session_ID: "",
              })
              .pipe(
                switchMap((ProductLines) => {
                  if (ProductLines) {
                    localStorage.setItem('isEligible', '1');
                    localStorage.removeItem('isEligibleEvent');
                    //eventBusService.emit('setFilterData',true);
                    return markFavouriteProductList.getFavouriteProductList();
                  } else {
                    return of({});
                  }
                }),
                switchMap((favourites) => {
                  eventBusService.emit('setFavourite', favourites);
                  return of({});
                 })
              )
              .subscribe({
                next: (data) => console.log('Data:', data),
                error: (error) => console.error('Error:', error),
                complete: () => console.log('Completed')
              });
            }

          }, 100);
        }
      },
      error: (error: any) => {
        console.error('Error:', error);
        localStorage.removeItem('isEligibleEvent');
      }
    });
  }, true);


  return (): Promise<any> => {
    return appInitService.initApp(null,true,true,true).pipe(
      mergeMap((appInitResponse: AmdocsAppInitResponse) => {
        if (appInitResponse.success) {
          if (window.location.href.indexOf('_callback') > -1) {
            let isdoc = sessionStorage.getItem("isdocumentCallback");
            if(!isdoc){
              router.navigate(['']);
            }
          }

        } else {
          if (!appInit.appInitError) {
            appInit.appInitError = appInitResponse.error;
          }
        }
        return of(appInitResponse);
      }),
        finalize(() => {
          appInit.appFinishedLoaded = true;
          document.body.className = document.body.className.replace(
            "show-splash",
            ""
          );
        })
      )
      .toPromise();
  };
}

const appSyncConfig: IAppSyncConfig = {
  url: environment.graphQlBaseURL,
  cnsUrl: environment.cnsBaseURL,
  region: 'us-east-1',
  initAppOperationName: 'ApplicationInit'
};

const appGatewayConfig: IAppGatewayConfig = {
  url: 'https://localhost:4200/appConfig',
  region: 'us-east-1',
};

const loginGatewayConfig: ILoginGatewayConfig = {
  url: environment.loginGWBaseURL,
  region: 'eu-west-1',
  appid: environment.appId,
  refreshTokenInterval: environment.refreshTokenInterval
};


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BannerComponent,
    PageBannerComponent,
    AccessDeniedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AmdocsUtilsModule,
    AmdocsUiModule,
    ShareModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AmdocsAppInitService,Router,AmdocsEventBusService,SessionService,EligibilityService,UserService,InitService,MarkUnMarkFavouriteProductLine],
      multi: true
    },    {
      provide: 'IAppSyncConfig',
      useValue: appSyncConfig
    },
    {
      provide: 'IAppGatewayConfig',
      useValue: appGatewayConfig
    },
    {
      provide: 'ILoginGatewayConfig',
      useValue: loginGatewayConfig
    },
    MetaDataService,
    UserService,
    InitService,
    SessionService,
    EligibilityService,
    NetworkService,
    MarkUnMarkFavouriteProductLine,
    DownloadDocumentService,
    AmdocsUiModule],
  bootstrap: [AppComponent]
})
export class AppModule {
}

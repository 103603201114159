import { Injectable } from "@angular/core";
import {AmdocsAppsyncClientService} from 'amdocs-core-package';
import {Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'  // This ensures the service is a singleton
})
export class MarkUnMarkFavouriteProductLine {
    constructor( 
      private appSyncClient: AmdocsAppsyncClientService) {
    }

     markUnmarkFavourite(input): Observable<any> {
        const favQuery = `mutation MarkUnMarkFavouriteProductLineMutation($saveFaouriteProdcutLineInput: SaveFaouriteProdcutLineInput!) {
          markUnMarkFavouriteProductLine(saveFaouriteProdcutLineInput: $saveFaouriteProdcutLineInput) {
            isSuccess
            message
          }
        }`;
        const param = input;

          return this.appSyncClient.call(favQuery, 'MarkUnMarkFavouriteProductLineMutation', param).pipe(
            mergeMap( (res: any): Observable<any> => {
              return of(res);
            }),
            catchError( err => {
              console.log(err);
              return of({error: err});
            })
        );
    }

    sortMarkedFavProducts(data) {
      return data.sort((a, b) => a.name.localeCompare(b.name));
    }

     getFavouriteProductList(): Observable<any> {
      const getFavList = `query GetMyFavouriteProductLineList {
        getMyFavouriteProductLineList{
            SysID
            name
            isMarkFavourite
          }
        }`;
      
        return this.appSyncClient.call(getFavList, 'GetMyFavouriteProductLineList').pipe(
          mergeMap((res: any): Observable<any> => {
           if(Boolean(
              res?.getMyFavouriteProductLineList?.length
            )){
              res.getMyFavouriteProductLineList = this.sortMarkedFavProducts(res.getMyFavouriteProductLineList);
            }
            return of(res);
          }),
          catchError(err => {
            return of(err);
          })
        )

    }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar/avatar.component';
import { AmdocsAppLoaderComponent } from './app-loader/amdocs-app-loader.component';
import { PermissionComponent } from './permission-issue/permission-issue.component';
import { SearchComponent } from './search/search.component';
import { AmdocsUiModule } from 'amdocs-core-package';
import { TermConditionComponent } from './terms-condition/terms-condition.component';
import { FooterComponent } from '../page-layout/footer/footer/footer.component';
import { DocumentCardComponent } from './documents-card/document-card/document-card.component';
import { CardsMenuComponent } from './cards-menu/cards-menu.component';
import {AutocompleteComponent} from './amdocs-autocomplete/amdocs-autocomplete.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from "@angular/forms";
import { DateFormatPipe } from '../filter/date-format.pipe';
import { ToastComponent } from './toast-component/toast.component';
import { AmdocsAppRouteLoaderComponent } from './app-route-loader/amdocs-app-route-loader.component';
import { ShimmerComponent } from './shimmer/shimmer.component';
import { FilterSearchComponent } from './filter-search/filter-search.component';
import { SearchDocumentListService } from '../service/searchDocumentList.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PaginationComponent } from './app-pagination/app-pagination/app-pagination.component';
import { QuicklinksComponent } from '../feature/productlines/quicklinks/quicklinks.component';
import { QuicklinksMenuComponent } from '../feature/productlines/quicklinks-menu/quicklinks-menu.component';
import { HighlightSearch } from './documents-card/document-card/highlight-search';
const imageVersion = '3';
const shareComponents = [
  AvatarComponent,
  AmdocsAppLoaderComponent,
  PermissionComponent,
  SearchComponent,
  TermConditionComponent,
  DocumentCardComponent,
  CardsMenuComponent,
  FooterComponent,
  AutocompleteComponent,
  ToastComponent,
  ShimmerComponent,
  DateFormatPipe,
  AmdocsAppRouteLoaderComponent,
  FilterSearchComponent,
  PageNotFoundComponent,
  PaginationComponent,
  QuicklinksComponent,
  QuicklinksMenuComponent,
  HighlightSearch
];

@NgModule({
  declarations: [shareComponents],
  imports: [
    CommonModule,
    AmdocsUiModule,
    NgSelectModule,
    FormsModule

  ],
  providers: [DateFormatPipe,SearchDocumentListService,{
    provide: 'IImageVersion',
    useValue: imageVersion
  }],

  exports: [shareComponents]
})
export class ShareModule { }

import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { AmdocsAuthService } from 'amdocs-core-package';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public promptEvent: any;
  public roleToken: string;
  public empId: string;
  public email:string;
  public employeeCode:string;
  private productLineSource = new BehaviorSubject<any | null>(null);

  // Expose the BehaviorSubject as an observable
  currentProductLine = this.productLineSource.asObservable();

  constructor(private authService: AmdocsAuthService,private http: HttpClient) {
  }

  private name = '';

  public setUserDetails(): void {
    const formattedToken: any = jwt_decode(this.authService.getToken());
    this.name = formattedToken['name'] ? formattedToken['name'] : '';
    this.empId = formattedToken['iat'] ? formattedToken['iat'] : '';
  }

  public getName(): string {
    const formattedToken: any = jwt_decode(this.authService.getToken());
    this.name = formattedToken['name'] ? formattedToken['name'] : '';
    return this.name;
  }

  public getEmail(): string {
    const formattedToken: any = jwt_decode(this.authService.getToken());
    this.email = formattedToken['preferred_username'] ? formattedToken['preferred_username'] : '';
    return this.email;
  }

  public getEmployeeCode(): string {
    const formattedToken: any = jwt_decode(this.authService.getToken());
    this.employeeCode = formattedToken['Employee-ID'] ? formattedToken['Employee-ID'] : '';
    return this.employeeCode;
  }

  public getEmpId(): string {
    return this.empId;
  }

  checkUrlStatus(url: string): Observable<any> {
    // Make a GET request to the URL and return the observable
    // Note: This example returns the full response to inspect the status code.
    return this.http.get(url, { observe: 'response' });
  }

  // Method to update the product line
  setProductLine(productLine: string) {
    this.productLineSource.next(productLine);
  }
}

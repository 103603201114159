import {Injectable} from '@angular/core';
import {AmdocsAppsyncClientService} from 'amdocs-core-package';
import {Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';


@Injectable()
export class MetaDataService {
  constructor( private appSyncClient: AmdocsAppsyncClientService) {
  }

  getDocumentMetaData(documentId,apsSessionId): Observable<any> {
  const metaDataQuery = `query GetDoumentMetaDataById($apsSessionId: String!,$documentId:String!) {
      getDoumentMetaDataById(apsSessionId: $apsSessionId, documentId: $documentId) {
        errorMessage
    expires
    nonHtmlDocumentUrl
    fusionDoumentResponse {
      facets {
        field {
          counts {
            count
            name
          }
          label
        }
      }
      meta {
        debug {
          debug
        }
        timing {
          mainQuery
          pipeline {
            stage
            time
          }
          total
        }
      }
      results {
        groupField
        list {
          hits
          maxScore
          docs {
            collection
            groupValue
            id
            type
            fields {
              APrdctTre
              DocCert
              DocDt
              DocNum
              DocTyp
              FVN
              KII
              KIN
              KIT
              LstUpdt
              PL
              PermsSts
              Prdct
              SNPT
              SPUrl
              SRC
              UnqI
              VR
              VSK
            }
            groupedDocs {
              hits
            }
            highlights {
              KIN {
                value
              }
            }
          }
          pagination {
            start
          }
        }
      }
    }
      }
    }
  `;
    const paramsvalues = {
      apsSessionId,
      documentId
    };
    return this.appSyncClient.call(metaDataQuery, 'GetDoumentMetaDataById', paramsvalues).pipe(
        mergeMap( (res: any): Observable<any> => {
          return of(res);
        }),
        catchError( err => {
          console.log(err);
          return of({error: err});
        })
    );
  }

  getDocumentInternalSearchData(documentId, searchKey): Observable<any> {

    const metaDataQuery = `query GetDoumentById($documentId:String!,$searchKey: String) {
       getDoumentById( documentId: $documentId, searchKey:$searchKey) {
         errorMessage
     expires
     fusionDoumentResponse {
       results {
         groupField
         list {
           hits
           maxScore
           docs {
             collection
             groupValue
             id
             type
             fields {
               SNPT
               SPUrl
               UnqI
               VSK
             }
             highlights {
               KIN {
                 value
               }
               SNPT{
                 value
               }
             }
           }
           pagination {
             start
           }
         }
       }
     }
       }
     }
 `;
    const paramsvalues = {
      documentId,
      searchKey
    };
    return this.appSyncClient.call(metaDataQuery, 'GetDoumentById', paramsvalues).pipe(
      mergeMap((res: any): Observable<any> => {
        return of(res);
      }),
      catchError(err => {
        console.log(err);
        return of({ error: err });
      })
    );
  }

}

import { Component } from '@angular/core';
import { AmdocsEventBusService } from 'amdocs-core-package';
@Component({
  selector: 'app-quicklinks-navigate',
  template: '',
})
export class QuickLinksNavigateComponent {
  public assetsPath:string="/assets/icons/";
constructor(private eventService:AmdocsEventBusService){
  this.eventService.emit('appLoaded',false);
  this.eventService.emit('clearAllSelections', true);
  this.eventService.emit('clearInputSearch', true);
}
}
import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { MarkUnMarkFavouriteProductLine } from './markFavourite.service';

@Injectable({
  providedIn: 'root'
})
export class SaveFavouriteService {
  private favourites = new BehaviorSubject<string>(null); // Buffer size of 1

  private favouritesObj = this.favourites.asObservable();
  constructor(private markUnmarkFavouriteService: MarkUnMarkFavouriteProductLine) {

  }

  // Function to update the value
  public updateFavourites(data: any): void {
    this.favourites.next(data);
  }

  // Function to get the Observable
  public getFavourites() {
    return this.favouritesObj;
  }

  addOrRemoveFavourites(item:any,isMarkFavourite: boolean) {
    let param = {
      saveFaouriteProdcutLineInput: {
        SysID:item.SysID,
        name:item.name,
        isMarkFavourite,
      },
    };
    this.markUnmarkFavouriteService
    ?.markUnmarkFavourite(param)
    ?.subscribe((data) => {
      return data
    });

  }
}
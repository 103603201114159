import {
  Component,
  ViewChild,
  Inject,
  ElementRef,
  OnInit,
  EventEmitter,
  Output,
  HostListener,
  Input
} from '@angular/core';
import { CONSTANTS } from 'src/app/constants';
import {
  AmdocsEventBusService,
  AmdocsDeviceService,
  AmdocsUserService,
} from 'amdocs-core-package';
import { FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { DownloadDocumentService } from 'src/app/service/downloadDocument.service';
import { catchError, Observable, of, tap } from 'rxjs';

@Component({
  selector: 'app-cards-menu',
  templateUrl: './cards-menu.component.html',
  styleUrls: ['./cards-menu.component.scss'],
})
export class CardsMenuComponent implements OnInit {
  courseData: any = {};
  @Output() close = new EventEmitter<void>();
  public isMobile: boolean = false;
  public menuOpen = false;
  public togglemenuOpen = false;
  public assetsPath = CONSTANTS.ASSETS_URL;
  public posLeft: any;
  public posTop: any;
  public isMenu: boolean = false;
  public resultsWrapperHeight: number;
  public employees: any[] = null;
  public employeeSearchControl: FormControl;
  public nextToken: string = 'learning';
  public showResults = false;
  public isFromSearchHistory = false;
  public showEndOfResults = false;
  public searchError: any = null;
  public isLoading = false;
  shareCourse: boolean = false;
  keyword: string = 'displayName';
  shareButton: boolean = false;
  isLoadingResult: boolean = false;
  select: boolean = false;
  email: string = '';
  emailBody: string = '';
  selectedEmpNames: string = '';
  peopleToShareWith: string = '';
  public id: string[] = [];
  public isContinueLearning: boolean = false;
  public item: any;
  public employeeList: any[] = null;
  public historyList: any[] = null;
  historyIcon: boolean = false;
  public addHistory = false;
  public markDisabled:boolean=false;
  public historyData: string = '';
  public href: string = "";
  mailtoLink: string;
  public hrefLink: string;
  selectedItems: any[] = [];
  openedModal:string = ''
  menuId:string='';
  public downloadFailed: string[] = [];
  public downloadFailedPopup: boolean = false;
  event$
  @ViewChild('cardmenu') cardmenu!: ElementRef;
  @ViewChild('auto') auto: any;
  @ViewChild('inputSearch') inputSearchElement: ElementRef;
  @ViewChild('loadMore') loadMorePlaceholderElement: ElementRef;
  @ViewChild('searchResults') searchResultsElement: ElementRef;
  @Input() cardIndex: any = 0;
  public openMenu:string='';
  size: any;
  courseDetailsComp: any;
  public menu:any=[];
  public cardData:any=[]
  constructor(
    private deviceService: AmdocsDeviceService,
    private eventBusService: AmdocsEventBusService,
    public amdocsUserService:AmdocsUserService,
    private el: ElementRef,
    private downloadService: DownloadDocumentService,
    @Inject(DOCUMENT) private document: Document
  ) {


  }

  private intersectionObserver: IntersectionObserver;
  ngOnInit(): void {
    this.menuOpen = false;
    this.isMobile = this.deviceService.isMobile();
    this.eventBusService.on(
      CONSTANTS.EVENTS.OPEN_OPTION_MODAL,
      (data: any) => {
        if(this.menuId!=data.id){
        this.openMenu=data.id;
        this.menu[data.id]=true;
        this.updateMailtoLink(data.data);
        this.cardData=data.data;
        this.menu[data.id]=!this.menuOpen;
        this.togglemenu();
        this.menuId=data.id;
        }else{
          this.menuId='';
        }
      },
      true
    );
  }

  download(item): void {
    this.eventBusService.emit(CONSTANTS.EVENTS.DOCUMENT_LOADER, true);
    const documentId = item?.UnqI;
    const documentName = item?.KIN[0];
    this.downloadService.getDownload(documentId)
      .pipe(
        tap((response: any) => this.handleApiResponse(response,documentName)),
        catchError(error => this.handleApiError(error))
      )
      .subscribe();
  }


  handleApiResponse(response: any, name:any): void {
    if(response?.getDocumentContent?.url){
       const url = response?.getDocumentContent?.url;
        this.eventBusService.emit(CONSTANTS.EVENTS.DOCUMENT_LOADER, false);
        const anchorUrl = document.createElement('a');
        anchorUrl.href = url;
        anchorUrl.download = ''; // Optional: specify a filename
        document.body.appendChild(anchorUrl);
        anchorUrl.click();
        document.body.removeChild(anchorUrl);

    }else{
      this.showDownloadFailedPopup(name);
      this.eventBusService.emit(CONSTANTS.EVENTS.DOCUMENT_LOADER, false);
      console.error('Error:', response);
    }
  }

  handleApiError(error: any): Observable<null> {
    this.eventBusService.emit(CONSTANTS.EVENTS.DOCUMENT_LOADER, false);
    console.error('Error:', error);
    return of(null); // Return a fallback value or empty observable
  }

  ngOnDestroy(): void {
    this.removeScrollListening();
  }
  updateMailtoLink(data : any) {
    this.hrefLink = window.location.origin;
    const docID = encodeURIComponent(data.UnqI);  // encoding the data.UnqI value
    const subject = 'Document(s) from the Amdocs documentation portal'
    const body = encodeURIComponent(`Hi,

    The following document(s) from the Amdocs documentation portal might interest you:

    ${data.KIN}
    ${this.hrefLink}/document?DocUID=${docID}&SOSessionID=&StartPage=`);
        this.mailtoLink = `mailto:?subject=${subject}&body=${body}`;  }




  setResultsWrapperHeight(): void {
    const currentBodyHeight = this.deviceService.screenHeight;
    this.resultsWrapperHeight = currentBodyHeight
      ? currentBodyHeight - 47
      : this.document.body.clientHeight - 47;
  }



  onSearchInputFocus(): void {
    console.log('Focussed');
  }
  onFocused(e) {
    // do something when input is focused

  }


  public togglemenu(): void {
    this.shareCourse = false;
    this.menuOpen = !this.menuOpen;
  }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if(!this.isClickInsideCardMenu(event)){
      this.openMenu='';
      this.menuId='';
    }
  }

  isClickInsideCardMenu(event: MouseEvent): boolean {
    let targetElement = event.target as HTMLElement;
    while (targetElement) {
      if (targetElement.classList && targetElement.classList.contains('openOptions')) {
        return true;
      }
      targetElement = targetElement.parentElement;
    }

    return false;
  }

  removeScrollListening(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
      this.intersectionObserver = null;
    }
  }

  closeDownloadError(){
    this.downloadFailed = [];
    this.downloadFailedPopup = false;
  }
  showDownloadFailedPopup(name:any){
    this.downloadFailed.push(name);
    this.downloadFailedPopup = true;
  }
}


export const CONSTANTS = {
  RECORDS_LIMIT_PAGE:10,
  SHARE_LIMIT:10,
  TOAST_TIME:10000,
  SHARE_LIMIT_MESSAGE:'You can select up to 10 documents at a time. You can download or share the selected documents or deselect some documents to proceed.',
    EVENTS: {
      LEARNING_REGISTER: 'learning-register',
      CLOSE_MODAL: 'close-modal',
      CLOSE_RATING_MODAL:'close-rating-modal',
      OPEN_OPTION_MODAL:'open-option-modal',
      OPEN_LEARNING_INITIATIVE:'open-learning-initiative',
      OPEN_RATING_MODAL:'open-rating-modal',
      PAGINATOR:'paginator',
      FILTEROUT:'filterout',
      MOBILEFILTEROUT:'mobilefilterout',
      SEARCH_TEXT:'search-text',
      IS_FOOTER_REQUIRED:'footer-required',
      IS_FOOTER_NOT_REQUIRED:'footer-not-required',
      IS_HEADER_REQUIRED:'header-required',
      IS_HEADER_NOT_REQUIRED:'header-not-required',
      OPEN_LEARNING_VIDEO:'learning-video',
      COURSE_STATUS:'course-status',
      CLOSE_LEARNING_VIDEO:'close-learning-video',
      OPEN_VIDEO:'open-video',
      HOMEPAGE_RELOAD_DATA: 'homepage-reload-data',
      FULLSCREEN:'fullscreen',
      EXITFULLSCREEN:'exitfullscreen',
      OPEN_SHARE_MODAL:'opensharemodal',
      CLOSE_SEARCH_MODAL:'closesearchmodal',
      SEARCH_MODAL:'searchmodal',
      CLOSE_RATE_MODAL:'closeratingmodal',
      APP_INIT:'appInit',
      OPEN_ERROR_MODAL:'openErrorModal',
      OPEN_ASSIGNMENT_MODAL:'openAssignmentModal',
      CLOSE_ASSIGNMENT_MODAL:'closeAssignmentModal',
      FUSIONPLANSEARCH:'fusionPlanSearch',
      SELF_ASSIGNMENT_ANALYTICS:'selfAssignmentAnalytics',
      CHECKBOX_SELECTED:'checkboxSelected',
      CHECKBOX_SELECTED_DOCUMENT:'checkboxSelectedDocument',
      CHECKBOX_DISABLED_DOCUMENT:'checkboxDisabledDocument',
      DOCUMENT_LOADER:'document_loader',
    },
    ASSETS_URL:"/assets/icons/",
    ASSETS_IMAGE_URL:"/assets/images/",
    ALERT_TIME:2500,
    CARDMENU:{
      CURRENT_CONFIGURATION:{
        TOP:50,
        LEFT:300,
      },
      PREVIOUS_CONFIGURATION:{
        TOP:'auto',
        LEFT:'0px',
        BOTTOM:'-50%'
      },

    }


}


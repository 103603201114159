import { Component, Input } from '@angular/core';
import { AmdocsEventBusService } from 'amdocs-core-package';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @Input() placeholderText = 'Search documentation';
  @Input() textName = 'Filters';
  @Input() isHomeScreen = true;
  @Input() isShrink=false;
  public ismodalOpen:boolean = false;
  public productLines: any;
  public clearFilter:boolean=false;
  public count:number=0;
  public userSearch:string='';
  public searchInTitle:boolean=false;
  public isChecked:boolean=false;
  constructor(private eventBusService:AmdocsEventBusService){
     this.eventBusService.on('filterCount', (data) => {
      this.count = data;
     }, true);

     this.eventBusService.on('updateSearchInput', (data) => {
      this.userSearch=data.searchKey;
      this.searchInTitle=data.inTitleOnly=='true'?true:false;
    },true);
  }
  openFilter(){
    this.ismodalOpen = !this.ismodalOpen;
    this.eventBusService.emit('openFilter',this.ismodalOpen);
  }

  clearsearchInput(){
    this.userSearch='';
    this.searchInTitle = false;
    this.eventBusService.emit('searchInputChange',{'userSearch':this.userSearch,'searchInTitle':this.searchInTitle});
  }

  changeSearch(){
    if(!this.userSearch){
      this.searchInTitle = false;
    }

    this.eventBusService.emit('searchInputChange',{'userSearch':this.userSearch,'searchInTitle':this.searchInTitle});
  }

  onSearch(){
      this.eventBusService.emit('searchInput',{'userSearch':this.userSearch,'searchInTitle':this.searchInTitle});

  }

  onSearchInTitleChange(){
    this.eventBusService.emit('searchInput',{'userSearch':this.userSearch,'searchInTitle':this.searchInTitle});
  }


  handleModalChange(newStatus: boolean) {
    this.ismodalOpen = newStatus;
  }

  clearSearch(event: Event){
    event.stopPropagation();
    this.searchInTitle = false;
    this.userSearch = ''
    this.eventBusService.emit('searchInputChange',{'userSearch':this.userSearch,'searchInTitle':this.searchInTitle});
    this.onSearch();
  }
}

<div class="main-item">
  <div class="static-background">
    <div class="background-masker btn-divide-left-1"></div>
    <div class="background-masker btn-divide-left-2"></div>
    <div class="background-masker btn-divide-left-3"></div>
  </div>
  <div class="static-dark-background">
    <div class="background-masker btn-divide-left-1"></div>
    <div class="background-masker btn-divide-left-2"></div>
    <div class="background-masker btn-divide-left-3"></div>
  </div>
  <div class="animated-background">
    <div class="background-masker btn-divide-left-1"></div>
    <div class="background-masker btn-divide-left-2"></div>
    <div class="background-masker btn-divide-left-3"></div>
  </div>
</div>

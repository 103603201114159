import { NgModule ,APP_INITIALIZER} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AmdocsAppInitResponse, IAppSyncConfig, IAppGatewayConfig, ILoginGatewayConfig,AmdocsUiModule,AmdocsUtilsModule } from 'amdocs-core-package';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './page-layout/header/header/header.component';

import { BannerComponent } from './page-layout/banner/banner/banner.component';
import { PageBannerComponent } from './page-layout/banner/banner/homePage/page-banner/page-banner.component';
import { catchError, finalize, mergeMap,switchMap } from "rxjs/operators";
import { of, throwError,firstValueFrom } from "rxjs";
import { environment } from 'src/environments/environment';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { InitService } from './service/init.service';
import { MetaDataService } from './service/metaData.service';
import { UserService } from './service/user.service';
import { ShareModule } from './shared/share.module';
import { SessionService } from './service/session.service';
import { EligibilityService } from './service/eligibility.service';
import { NetworkService } from './service/network.service';
import { MarkUnMarkFavouriteProductLine } from './service/markFavourite.service';
import { AccessDeniedComponent } from './feature/accessdenied/accessDenied.component';
import { DownloadDocumentService } from './service/downloadDocument.service';
import { CONSTANTS } from './constants';
import { AppServices } from './service/app.service';
import { ErrorInterceptor } from './interceptor/error.interceptor';
export function initializeApp(
  appService: AppServices,
  MarkFavouriteProductList:MarkUnMarkFavouriteProductLine


): any {
  const appInit = appService.appInitService;
  const currentUrl  = window.location.href;
    if(currentUrl.indexOf("?") > -1){
    const paramss = currentUrl.split('?'); // Remove the first empty element
    const queryString =paramss[1]
    let params;
    if(paramss[1].includes('&')){
       params = queryString.split('&');
    const queryParams: any = {};
    if(params.length>1){
    params.forEach(param => {
      const [key, value] = param.split('=');
      queryParams[key] = value;

    });
    const docUID = queryParams['DocUID']?queryParams['DocUID']:'';
    const soSessionID = queryParams['SOSessionID']?queryParams['SOSessionID']:'';
    const startPage = queryParams['StartPage']?queryParams['StartPage']:'';
    if(docUID) sessionStorage.setItem(CONSTANTS.SessionSTORAGE.documentId, docUID);
    if(soSessionID)sessionStorage.setItem(CONSTANTS.SessionSTORAGE.SOSessionID, soSessionID);
    if(startPage)sessionStorage.setItem(CONSTANTS.SessionSTORAGE.startPage, startPage);
    if(docUID) sessionStorage.setItem(CONSTANTS.SessionSTORAGE.isdocumentCallback,'1');

  }
    }
    else{
      if(paramss[1].includes('SOSessionID')){
        const SOSessionID= paramss[1].split('=')[1];
        if(SOSessionID) {
          sessionStorage.setItem(CONSTANTS.SessionSTORAGE.SOSessionID,SOSessionID);
          appService.router.navigate(['/']);
        }
      }
      else if(paramss[1].includes('searchID')){
        const shareId= paramss[1].split('=')[1];
        if(shareId) sessionStorage.setItem(CONSTANTS.SessionSTORAGE.shareId,shareId);
      }
    }

  }

  appService.eventBusService.on('initLogin', (data: any) => {
    localStorage.setItem(CONSTANTS.STORAGE.isEligibleEvent,'1');
    localStorage.setItem(CONSTANTS.STORAGE.isFirstLogin,'1');
    localStorage.removeItem(CONSTANTS.STORAGE.userEligibility);
    localStorage.removeItem(CONSTANTS.STORAGE.isProductLine);
    appService.sessionService.setSessionData()
    .pipe(
      mergeMap((response: any) => {
       let userData= {
            "SO_Session_ID": sessionStorage.getItem(CONSTANTS.SessionSTORAGE.SOSessionID)?sessionStorage.getItem(CONSTANTS.SessionSTORAGE.SOSessionID):'',
            "User_ID": appService.userService.getEmail(),
            "IP_Details": "",
            "Emp_ID": appService.userService.getEmployeeCode(),
            "DaC_Session_ID": ""

        }
        return appService.eligibilityService.getEligibility(userData);
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    )
    .subscribe({
      next: (response: any) => {
          const currentTime = Date.now();
          const eligibilityDuration = currentTime + CONSTANTS.CHECK_ELIGIBILITY_TIMER * 60 * 1000;;
         if(response){
          let isElig='false';
          if(response?.getEligibilityRuleFromAPS!=null && response?.getEligibilityRuleFromAPS?.params['IsEligible']){
            isElig='true';
          const filterObject = response?.getEligibilityRuleFromAPS?.queryDefinition.filter.find(f => f.field === 'PermsSts');
          const filterValues = filterObject ? filterObject.values : [];
          const isInternal = filterValues.includes('internal')?'true':'false';
          localStorage.setItem(CONSTANTS.STORAGE.isInternal,isInternal);
          localStorage.setItem(CONSTANTS.STORAGE.eligibiltyDuration, eligibilityDuration.toString());
          }else{
            localStorage.setItem(CONSTANTS.STORAGE.eligibiltyDuration, eligibilityDuration.toString());
            isElig='false';
          }
          localStorage.setItem(CONSTANTS.STORAGE.userEligibility,isElig);
          setTimeout(() => {
            // Emit an event
            appService.eventBusService.emit(CONSTANTS.EVENTS.IS_ELIGIBLE,isElig);
            let isdoc = sessionStorage.getItem(CONSTANTS.SessionSTORAGE.isdocumentCallback);
            if (!isdoc && isElig=='true') {
              appService.initService.getProductLineMetaData({
                User_ID: appService.userService.getEmail(),
                Emp_ID: appService.userService.getEmployeeCode(),
                SO_Session_ID: "",
                IP_Details: "",
                DaC_Session_ID: "",
              })
              .pipe(
                switchMap((ProductLines) => {
                  if (ProductLines) {
                    localStorage.removeItem(CONSTANTS.STORAGE.isEligibleEvent);
                    localStorage.setItem(CONSTANTS.STORAGE.isProductLine, '1');
                    return appService.markFavouriteProductList.getFavouriteProductList();
                    
                  } else {
                    localStorage.setItem(CONSTANTS.STORAGE.isProductLine, '1');
                    return of({});
                  }
                }),
                switchMap((favourites) => {
                  appService.eventBusService.emit('setFavourite', favourites);
                  return of({});
                 })
              )
              .subscribe({
                next: (data) => console.log('Data:', data),
                error: (error) => console.error('Error:', error),
                complete: () => console.log('Completed')
              });
            }  else if (isdoc && isElig=='true'){
              localStorage.setItem(CONSTANTS.STORAGE.isProductLine, '1');
            }

          }, 100);
        }
      },
      error: (error: any) => {
        console.error('Error:', error);
        localStorage.removeItem(CONSTANTS.STORAGE.isEligibleEvent);
      }
    });
  }, true);


  return (): Promise<any> => {
    return firstValueFrom(
      appService.appInitService.initApp(null,true,true,true).pipe(
      mergeMap((appInitResponse: AmdocsAppInitResponse) => {
        if (appInitResponse.success) {
          if (window.location.href.indexOf('_callback') > -1) {
            let isdoc = sessionStorage.getItem(CONSTANTS.SessionSTORAGE.isdocumentCallback);
            if(!isdoc){
              appService.router.navigate(['']);
            }
          }

        } else {
          if (!appInit.appInitError) {
            appInit.appInitError = appInitResponse.error;
          }
        }
        return of(appInitResponse);
      }),
        finalize(() => {
          appInit.appFinishedLoaded = true;
          document.body.className = document.body.className.replace(
            "show-splash",
            ""
          );
        })
      ));
  };
}

const appSyncConfig: IAppSyncConfig = {
  url: environment.graphQlBaseURL,
  cnsUrl: environment.cnsBaseURL,
  region: 'us-east-1',
  initAppOperationName: 'ApplicationInit'
};

const appGatewayConfig: IAppGatewayConfig = {
  url: 'https://localhost:4200/appConfig',
  region: 'us-east-1',
};

const loginGatewayConfig: ILoginGatewayConfig = {
  url: environment.loginGWBaseURL,
  region: 'eu-west-1',
  appid: environment.appId,
  refreshTokenInterval: environment.refreshTokenInterval
};


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BannerComponent,
    PageBannerComponent,
    AccessDeniedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AmdocsUtilsModule,
    AmdocsUiModule,
    ShareModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppServices],
      multi: true
    },    {
      provide: 'IAppSyncConfig',
      useValue: appSyncConfig
    },
    {
      provide: 'IAppGatewayConfig',
      useValue: appGatewayConfig
    },
    {
      provide: 'ILoginGatewayConfig',
      useValue: loginGatewayConfig
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MetaDataService,
    UserService,
    InitService,
    SessionService,
    EligibilityService,
    NetworkService,
    MarkUnMarkFavouriteProductLine,
    DownloadDocumentService,
    AmdocsUiModule],
  bootstrap: [AppComponent]
})
export class AppModule {
}

<div class="banner" data-qa="banner-section" [ngClass]="{'banner-slide': isShrink}">
    <div class="card w-100 banner">
        <div class="banner-wd">
                <div class="title">
                    {{title}}
                </div>
                <div class="mt-4 searchDia">
                    <app-search [isShrink]="isShrink"></app-search>
                </div>
        </div>
        <div class="bannerImg">
            <img *ngIf="!isShrink" class="img-fluid" src="/assets/images/home_banner.png">
            <img *ngIf="isShrink" class="img-fluid" src="/assets/icons/banner-with-width.png">
        </div>
    </div>
</div>
import {Injectable} from '@angular/core';
import {AmdocsAppsyncClientService} from 'amdocs-core-package';
import {Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
@Injectable()
export class EligibilityService {
  constructor( private appSyncClient: AmdocsAppsyncClientService) {
  }
  getEligibility(apsSupportOneRequest): Observable<any> {
    const eligibilityQuery = `query GetEligibilityRuleFromAPS($apsSupportOneRequest: ApsSupportOneRequestInput!) {
      getEligibilityRuleFromAPS(apsSupportOneRequest: $apsSupportOneRequest) {
        params {
           Dac_SessionID
            ErrorDesc
            IsEligible
            SO_SessionID
            UPN
            User_ID
        }
        queryDefinition {
          userQuery
            filter {
              field
              type
              values
            }
          }
        results {
          size
          group {
            field
            size
          }
          sort {
            order
            value
          }
          start
        }
      }
    }
  `;
    const paramsvalues = {
      apsSupportOneRequest:apsSupportOneRequest
    };
    return this.appSyncClient.call(eligibilityQuery, 'GetEligibilityRuleFromAPS', paramsvalues).pipe(
        mergeMap( (res: any): Observable<any> => {
          return of(res);
        }),
        catchError( err => {
          console.log(err);
          return of({error: err});
        })
    );
  }
}


import { Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EligibilityService } from '../service/eligibility.service';
import { UserService } from '../service/user.service';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { CONSTANTS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class EligibilityGuard {
  constructor(private eligibilityService: EligibilityService, private router: Router,private userService:UserService,private eventBus:AmdocsEventBusService) {}

  canActivate: CanActivateFn = (): Observable<boolean> | boolean => {
    const currentTime = Date.now();
    const eligibilityDuration = currentTime + CONSTANTS.CHECK_ELIGIBILITY_TIMER * 60 * 1000;
    const eligibilityDurationUserStorage = localStorage.getItem(CONSTANTS.STORAGE.eligibiltyDuration);
 
    
    let isUserEligible: any=localStorage.getItem(CONSTANTS.STORAGE.userEligibility);
    const eligibilityUpdatedNeeded = this.checkEligibilityUpdateNeeded(eligibilityDurationUserStorage, currentTime, isUserEligible);
    if (this.userService.getEmail() && !localStorage.getItem(CONSTANTS.STORAGE.isFirstLogin)  && eligibilityUpdatedNeeded) {
      const userData = this.getUserData();
        return this.eligibilityService.getEligibility(userData).pipe(
          map(eligible =>  this.handleEligibilityResponse(eligible, eligibilityDuration)),
          catchError(() => {
            this.router.navigate(['/access-denied']);
            return of(false);
          })
        );
      }else{
        localStorage.removeItem(CONSTANTS.STORAGE.isFirstLogin);
        return of(true);
      }
  };

  private checkEligibilityUpdateNeeded(
    eligibilityDurationUserStorage: string | null,
    currentTime: number,
    isUserEligible: string | null
  ): boolean {
    if (
      eligibilityDurationUserStorage &&
      (Number(eligibilityDurationUserStorage) <= currentTime || !isUserEligible || isUserEligible === 'false')
    ) {
      return true;
    }
    return false;
  }

  private setEligibilityData(eligible: any, eligibilityDuration: number): void {
    localStorage.setItem(CONSTANTS.STORAGE.userEligibility, 'true');
    const filterObject = eligible?.getEligibilityRuleFromAPS?.queryDefinition.filter.find(f => f.field === 'PermsSts');
    const filterValues = filterObject ? filterObject.values : [];
    const isInternal = filterValues.includes('internal') ? 'true' : 'false';
    localStorage.setItem(CONSTANTS.STORAGE.isInternal, isInternal);
    localStorage.setItem(CONSTANTS.STORAGE.eligibiltyDuration, eligibilityDuration.toString());
  }

  private getUserData() {
    return {
      "SO_Session_ID": sessionStorage.getItem(CONSTANTS.SessionSTORAGE.SOSessionID) || '',
      "User_ID": this.userService.getEmail(),
      "IP_Details": "",
      "Emp_ID": this.userService.getEmployeeCode(),
      "DaC_Session_ID": ""
    };
  }

  private handleEligibilityResponse(eligible: any, eligibilityDuration: number): boolean {
    if (!localStorage.getItem(CONSTANTS.STORAGE.isProductLine)) {
      localStorage.setItem(CONSTANTS.STORAGE.isProductLine, '1');
    }
    if (eligible?.getEligibilityRuleFromAPS?.params['IsEligible']) {
      this.setEligibilityData(eligible, eligibilityDuration);
      setTimeout(() => {
        if (localStorage.getItem(CONSTANTS.STORAGE.isEligibleEvent)) {
          localStorage.removeItem(CONSTANTS.STORAGE.isEligibleEvent);
        }
        this.eventBus.emit(CONSTANTS.EVENTS.IS_ELIGIBLE, 'true');
      }, 100);
      return true;
    } else {
      setTimeout(() => {
        this.eventBus.emit(CONSTANTS.EVENTS.IS_ELIGIBLE, 'false');
      }, 1000);
      this.router.navigate(['/access-denied']);
      return false;
    }
  }
}
<!-- Search form -->
<div class="container" [ngClass]="{'banner-slide': isShrink}">
    <div class="form-section">
        <img src="/assets/icons/search.svg" class="searchIcon">
        <input [(ngModel)]="userSearch" (keydown.enter)="onSearch()" (input)="changeSearch()"  class="form-control search_height" type="text" placeholder="{{placeholderText}}" aria-label="Search">

        <div class="hstack gap-2 rightItems" (click)="openFilter()">
          <img alt="closeIcon" *ngIf="userSearch" (click)="clearSearch($event)" src="/assets/icons/close.svg" />
            <div class="vr">
            </div>
            <div>
                <img  src="/assets/icons/filter.svg">
            </div>
            <div class="textColor" placement="top-left">
                {{textName}} <span *ngIf="count>=0" [ngClass]="{'filter-count':count>0}">{{count>=1?count:''}}</span>
            </div>
        </div>
        <app-filter-search (clearuserSearch)="clearsearchInput()" [searchInTitle]="searchInTitle"  [searchInput]="userSearch"  [isClearable]="clearFilter" [ismodalOpen]="ismodalOpen" (modalOpenChange)="handleModalChange($event)"></app-filter-search>
    </div>


    <div class="py-0 ml-row hstack list-row" [ngClass]="{'banner-slide': isShrink}">
        <div class="vr">
        </div>
        <div class="text-light hstack">
            <input [disabled]="!userSearch" (change)="onSearchInTitleChange()" [(ngModel)]="searchInTitle" class="form-check-input custom-checkbox mb-2" type="checkbox"
            id="searchInTitle" name="selectAllChk">
           <p class="ps-2 mt-2 pt-1 searchTitle">Search in title only</p>
        </div>
    </div>
</div>


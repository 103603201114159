import { NgModule } from '@angular/core';
import { DefaultUrlSerializer, RouterModule, Routes, UrlSerializer, UrlTree } from '@angular/router';
import { QuickLinksNavigateComponent } from './feature/productlines/quicklinks/quicklinks-navigation.component';
import { EligibilityGuard } from './guards/eligibility.guard';
import { AccessDeniedComponent } from './feature/accessdenied/accessDenied.component';
export class CustomUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
    return super.parse(url);
  }

  serialize(tree: UrlTree): string {
    const url = super.serialize(tree);
    return url.replace(/%28/g, '(').replace(/%29/g, ')');
  }

}

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./feature/home/home.module').then(m => m.HomeModule),
     canActivate: [EligibilityGuard]
  },
  {
    path: 'document',
    loadChildren: () => import('./feature/document/document.module').then(m => m.DocumentModule),
  },
  {
    path: 'productline',
    loadChildren: () => import('./feature/productlines/productlines.module').then(m => m.ProductLinesModule),
     canActivate: [EligibilityGuard]
  },
  {
    path: 'searchresults',
    loadChildren: () => import('./feature/documentSearch/documentSearch.module').then(m => m.DocumentSearchModule),
     canActivate: [EligibilityGuard]
  },
  {
    path: 'quicklinksnavigate', 
    component: QuickLinksNavigateComponent
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
   { path: '**',
    loadChildren: () => import('./shared/redirect/redirect.module').then(m => m.RedirectModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  providers: [{ provide: UrlSerializer, useClass: CustomUrlSerializer }],
  exports: [RouterModule]
})
export class AppRoutingModule { }

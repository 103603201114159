import { Injectable } from '@angular/core';
import { AmdocsAppsyncClientService } from 'amdocs-core-package';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class SearchDocumentListService {
  constructor(private appSyncClient: AmdocsAppsyncClientService) {}

  getdocumentList(filterCriteria: any, queryName: string,isCategoryName:boolean=false): Observable<any> {
    const isCategory=isCategoryName;
    const documentListQuery = this.constructQuery(queryName,filterCriteria,isCategory);
    const paramsvalues = { filterCriteria };

    return this.appSyncClient.call(documentListQuery, queryName, paramsvalues).pipe(
      mergeMap((res: any): Observable<any> => of(res)),
      catchError(err => {
        console.log(err);
        return of({ error: err });
      })
    );
  }

  private constructQuery(queryName: string,filterCriteria: any,isCategory:boolean): string {
    const facetsFields = `
     facets {
     field {
      Cmpnt {
        label
        counts {
          count
          name
        }
      }
      PermsSts {
        label
        counts {
          count
          name
        }
      }
      PL {
        label
        counts {
          count
          name
        }
      }
      Prdct {
        label
        counts {
          count
          name
        }
      }
      DocTyp {
        label
        counts {
          count
          name
        }
      }
      DocCategory {
        label
        counts {
          count
          name
        }
      }
      FVN {
        label
        counts {
          count
          name
        }
      }
      VR {
        label
        counts {
          count
          name
        }
      }
      }
    }
    `;

    const highlightsFields = `
    highlights {
      KIN {
        value
      }
      SNPT {
        value
      }
    }
  `;

    const resultsFields = `
      results {
        groupField
        list {
          hits
          maxScore
          docs {
            collection
            groupValue
            groupedDocs {
              hits
              docs {
                collection
                type
                id
                fields {
                  APrdctTre
                  DocCert
                  DocDt
                  DocCategory
                  DocNum
                  DocTyp
                  FVN
                  KII
                  KIN
                  PermsSts
                  PL
                  LstUpdt
                  KIT
                  Prdct
                  SNPT
                  SPUrl
                  SRC
                  UnqI
                  VR
                  VSK
                  FileT
                }
                ${filterCriteria?.searchKey!='' ? highlightsFields : ''}
              }
            }
            fields {
              APrdctTre
              DocCert
              DocDt
              DocNum
              DocTyp
              FVN
              KII
              KIN
              PermsSts
              PL
              LstUpdt
              KIT
              Prdct
              SNPT
              SPUrl
              SRC
              UnqI
              VR
              VSK
              FileT
              DocCategory
            }
             ${filterCriteria?.searchKey!='' ? highlightsFields : ''}
          }
        }
      }
    `;

    const queryBody = `
      categoryFilterDocumentResponse {
            ${!isCategory && queryName!=='DocumentSearchPagination' ? facetsFields  :''}
             ${queryName !== 'FilterSearch' && queryName !== 'ProductCategoryListFilter' ? resultsFields : ''}
      }
      error {
        code
        message
      }
      expires
      statusCode
    `;

    return `
      query ${queryName}($filterCriteria: SearchCriteriaInput!) {
        searchDocumentList(filterCriteria: $filterCriteria) {
          ${queryBody}
        }
      }
    `;
  }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { catchError, map, of } from 'rxjs';
import { EligibilityService } from 'src/app/service/eligibility.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-accessDeenied',
  templateUrl: './accessDenied.component.html'
})
export class AccessDeniedComponent{

  constructor(private eventBus: AmdocsEventBusService,private router: Router,private userService:UserService,private eligibilityService:EligibilityService) {
     this.eventBus.emit('appLoaded',true);
     this.checkisEligible();
  }

  checkisEligible() {
    const navigation = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
    console.log(navigation.type);
    console.log(localStorage.getItem('docsel'));
        if (navigation.type == 'reload' && localStorage.getItem('docsel') && localStorage.getItem('docsel')=='false') {
          console.log('inside');
          let userData= {
            "SO_Session_ID": sessionStorage.getItem('SOSessionID')?sessionStorage.getItem('SOSessionID'):'',
            "User_ID": this.userService.getEmail(),
            "IP_Details": "",
            "Emp_ID": this.userService.getEmployeeCode(),
            "DaC_Session_ID": ""
          }
      this.eligibilityService
      .getEligibility(userData)
      .subscribe((eligible) => {
        console.log(eligible);
        if(eligible.getEligibilityRuleFromAPS.params['IsEligible']==true){
          this.router.navigate(['/']);
          }
      }); 
      
    }
  }
  
}

<div class="page-not-found">
  <div class="error-content">
    <img src="/assets/icons/pageNotFound.svg" alt="pageNotFound" class="error-image" />
    <h1>Page not found</h1>

    <p class="page-fnt">
      Sorry, this page is not available. The link you followed may be broken, or the page does not exist.
      It is also possible that you do not have permission to view the page.
    </p>
    <div class="details">
    <ul class="page-fnt">
      <li>Use the search bar to find the information you need.</li>
      <li>Go back to the<a (click)="openPreviousPage()" > previous page.</a></li>
      <li>Visit our<a (click)="openHomePage()" > homepage.</a></li>
      <li>Contact<a href="mailto:care@yourdomain.com"> Amdocs product support.</a></li>
    </ul>
  </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {AmdocsAppsyncClientService} from 'amdocs-core-package';
import {Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
@Injectable()
export class DownloadDocumentService {
  constructor( private appSyncClient: AmdocsAppsyncClientService) {
  }
  getDownload(DocNum): Observable<any> {
    const downloadDocumentQuery = `query GetDocumentContent($DocNum: String!) {
    getDocumentContent(DocNum:$DocNum) {
    ContentResponse {
      ErrorMsg
      FileByteStream
    }
  }
}
  `;
    const paramsvalues = {
      DocNum:DocNum
    };
    return this.appSyncClient.call(downloadDocumentQuery, 'GetDocumentContent', paramsvalues).pipe(
      mergeMap((res: any): Observable<any> => {
        // Assuming res contains headers and body
        const headers = res.headers || {}; // Adjust based on actual response structure
        const body = res.body || res; // Adjust based on actual response structure
        return of({ headers, body });
      }),
      catchError(err => {
        console.log(err);
        return of({ error: err });
      })
    );
  }
}

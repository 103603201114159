import {Component, Input} from '@angular/core';
import {AmdocsAuthService, AmdocsUserService} from 'amdocs-core-package';
import {environment} from '../../../environments/environment';
import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {

  @Input() size: AvatarImgSize = AvatarImgSize.medium;
  @Input() userName: string = null;
  @Input() uuid: string = null;
  @Input() showShortName = false;
  public showDefaultImage = false;

  constructor(private userService: AmdocsUserService,private authService:AmdocsAuthService) {
  }

  get fullPath(): string {
    let formattedToken='';
    let uuid:any='';
    if (jwt_decode(this.authService.getToken())) {
      formattedToken = jwt_decode(this.authService.getToken());
      uuid = formattedToken['oid'];
    }
    
    return `${environment.imgUrl}/${uuid}?v=${environment.avatarsVersion}`;
  }

  get userShortName(): string {
    let formattedToken: any = '';
    let emplname='';
    if (jwt_decode(this.authService.getToken())) {
      formattedToken = jwt_decode(this.authService.getToken());
       emplname = formattedToken.name ? formattedToken.name : '';
    }
    const userName:any= this.userName ? this.userName : emplname;
    if (!userName) {
        return '';
    }
    const firstLetters =userName ?  userName.replace(/<b>/g, "").replace(/<\/b>/g, "").replace(/[0-9]/g, "").match(/\b(\w)/g):''; //
    return firstLetters ? firstLetters.join('') : '';

  }

  get fontClass(): string {
    const defaultClass = `${this.size}`;
    switch (this.size) {
      case AvatarImgSize.small: return `${defaultClass} text-medium-normal`;
      case AvatarImgSize.medium: return `${defaultClass} label-h3`;
      case AvatarImgSize.big: return `${defaultClass} label-h02`;
      case AvatarImgSize.normal: return `${defaultClass} text-medium-normal`;
    }
  }

}

export enum AvatarImgSize {
  small = 'small',
  medium = 'medium',
  big = 'big',
  normal ='normal'
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  AmdocsEventBusService,
} from 'amdocs-core-package';
@Component({
  selector: 'amdocs-autocomplete',
  templateUrl: './amdocs-autocomplete.component.html',
  styleUrls: ['./amdocs-autocomplete.component.scss']
})
export class AutocompleteComponent implements OnChanges {


  @Input() label: string;
  @Input() items: IAutocompleteItem[];
  @Input() selectedItemCode: any=[];
  @Input() placeholder = 'select';
  @Input() virtualScroll = false;
  @Input() readOnly = false;
  @Input() highlightResults = true;
  @Input() clearable = true;
  @Input() hideLabel: boolean = false;
  @Input() hasHierarchy = true;
  @Input() customClass: string;
  @Output() itemChangedCallback: EventEmitter<IAutocompleteItem> = new EventEmitter();
  @Output() selectedItemCleared: EventEmitter<any> = new EventEmitter();
  @Output() selectOpen: EventEmitter<any> = new EventEmitter();
  @Input() errorsMessage: string;
  @Input() isRequired = false;
  @Input() disabled = false;
  @Input() noDropdown = false;
  @Input() isNotOpen = false;
  @Input() isRounded = false;
  @Input() isMultiple = false;
  @Input() selectIfOneItem = true;
  @Input() hasTooltip = false;
  @Input() tooltipContent: string;
  @Input() hasSearchBox = false;
  @Input() clearOnBackspace = true;
  @Input() searchPlaceholder = '';
  @Input() selectedItemsDisplayPosition: string = '';
  @Input() isPlaceHolder:boolean=true;
  @Input() isApplyButton:boolean=false;
  @Input() id:any='';
  @Input() isApply:boolean=false;
  @ViewChildren('checkbox') checkboxes: QueryList<ElementRef>;
  @ViewChild('selectAll') selectAll: ElementRef;
  @ViewChild('mySelect') ngSelect: NgSelectComponent;
  @ViewChild('searchInput') searchValue!: ElementRef;
  selectedItems = [];
  allSelected = false;
  filteredItems: IAutocompleteItem[];
  searchPlanFC = new UntypedFormControl();
  isAllChecked:boolean=false;
  isSelectAllChecked: boolean = false;
  public searchQuery: string = '';
  @Output() applyFilterCallback: EventEmitter<any> = new EventEmitter();
  @ContentChild('autocompleteOptionTemplate') autocompleteOptionTemplate: TemplateRef<ElementRef>;
  constructor(private cdr: ChangeDetectorRef,private eventBusService: AmdocsEventBusService,) {
  }

  ngOnInit(){

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.filteredItems = this.items;
    if (changes.items) {
      if (this.items?.length === 1 && this.selectIfOneItem) {
        // disabled because MHL-3079;
        // this.selectedItemCode = this.items[0].code;
        // this.itemChangedEvent()
      } else {
        if (this.selectedItemCode?.length) {

          const tmpItems: any = changes.items.currentValue;
          if (tmpItems && (tmpItems as any[]).find) {
            if (!(tmpItems as any[]).find(i => i.name === this.selectedItemCode)) {
              this.itemChangedEvent();
            }

          }
        }
      }
    }
  }

clear(){
  this.selectedItemCode = [];
  this.ngSelect.clearModel();
}
  onSelectOpen(): void {
    if(this.noDropdown) {
      this.isNotOpen = true
      this.selectOpen.emit();
    }

    setTimeout(() => {
      this.isNotOpen = null
    }, 0)
    let allChecked = true;

    this.checkboxes.forEach((checkboxElementRef) => {
        const checkbox = checkboxElementRef.nativeElement as HTMLInputElement;
        if (this.selectedItems && Array.isArray(this.selectedItems)) {
          checkbox.checked = this.selectedItems.includes(checkbox.name);
        } else {
          checkbox.checked = false; // or any default value you prefer
        }
    });

  }

  onSelectClose() {
    this.ngSelect.close();
  }

  getValidItemCount(items: { count: number, name: string }[], filterItems: { count: number, name: string }[]): number {
    return items.filter(item =>
      filterItems.some(filter => item.name.toLowerCase() === filter.name.toLowerCase())
    ).length;
  }

  public filterItems(term: string): void {
    if (term) {
      this.filteredItems = this.items.filter(item =>
        item.name.toLowerCase().includes(term.toLowerCase())
      );
    } else {
      this.filteredItems = this.items; // Reset to all items if search term is cleared
    }
  }

  onClose(){
    if(this.noDropdown==false) {
      this.isNotOpen = false
      this.searchPlanFC.reset();
    }
    if(this.noDropdown) {
      this.isNotOpen = true
      this.selectOpen.emit();
    }

    setTimeout(() => {
      this.isNotOpen = null
    }, 0)
  }

  close(){
  this.isNotOpen=false;
  this.searchPlanFC.reset();
  }

  updateCheckboxStates(): void {
    this.searchPlanFC.reset();
    this.onCheckboxClick();
    this.eventBusService.emit('filterOpened',true)
  }

  onSearch(term: string, item): boolean {
    if (!item || !item.name) {
      return false; // or any default value you prefer
    }

    const formattedTerm = term.toLowerCase();
    const formattedName = item.name.replace(',', '').toLowerCase();

    return formattedName.includes(formattedTerm);
  }

  onInputSearch(term: string) {
   this.searchQuery = term;
    if (term) {
      this.filteredItems = this.items.filter(item =>
        item.name.toLowerCase().includes(term.toLowerCase())
      );
    } else {
      this.filteredItems = this.items; // Reset to all items if search term is cleared
    }

  }

  public itemChangedEvent($event:any=''): void {
    let selectedItem;
    const selectAllCheckbox = this.selectAll?.nativeElement as HTMLInputElement;
    if ($event?.target?.value=='on') {
    // Assuming isSelectAllChecked is a boolean indicating the state of the "Select All" checkbox

    if(this.searchQuery==''){
      if(selectAllCheckbox.checked){
        selectedItem = this.items.map(item => item.name);
      }else{
        selectedItem = [];
      }
    }else{
      if(selectAllCheckbox.checked){

      const filteredItems = this.searchQuery ? (this.items.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()))) : this.items;
       selectedItem = filteredItems.map(item => item.name);
      }else{
        selectedItem = [];
      }
    } // Emit filtered items
    this.isSelectAllChecked=false;
  } else if ((this.isMultiple && $event && $event[0]?.name) || ($event.length==0)) {
    //if($event?.srcElement && $event?.srcElement.id!='searchPlan'){
      selectedItem = this.selectedItemCode?.every(elem => this.items.filter(i => i.name.toLowerCase().includes(elem.toLowerCase()))) ? this.selectedItemCode : [];
    } else {
      selectedItem = this.items.find(i => i.name === this.selectedItemCode);
    }
    this.itemChangedCallback.emit(selectedItem);
    //settimeout function

      this.onCheckboxClick();// Code to be executed after a delay


  }

  onCheckboxClick() {
    setTimeout(() => {
      // Your existing code here

    const selectAllCheckbox = this.selectAll?.nativeElement as HTMLInputElement;
    if(selectAllCheckbox && this.filteredItems?.length != 0 && this.filteredItems?.length == this.checkboxes.filter((checkboxElementRef) => {
      const checkbox = checkboxElementRef.nativeElement as HTMLInputElement;
      return checkbox.checked;
    }).length)
    {
      selectAllCheckbox.checked = true;
      this.isAllChecked = true;
    }else{
      if(selectAllCheckbox){
      selectAllCheckbox.checked = false;
      this.isAllChecked = false;
      }
    }
  }, 100);

  }
  checkCount() {
    // Handle change event to update individual selection
    // and manage the state of the "Select All" checkbox
    const selectedCheckboxesLength = this.checkboxes.filter((checkboxElementRef) => {
      const checkbox = checkboxElementRef.nativeElement as HTMLInputElement;
      return checkbox.checked;
    })?.length;

   // Log the count of selected checkboxes

    // Existing logic...
    this.allSelected = this.items?.length === this.selectedItems?.length;
    if(!this.allSelected){
    }
  }


  triggerClickOnAllCheckboxes(setChecked: boolean): void {
    this.isSelectAllChecked = setChecked;
    const itemsToSelect = [];
    const itemsToClear = [];
    this.checkboxes.forEach((checkboxElementRef,index) => {
      const checkbox= checkboxElementRef.nativeElement as HTMLInputElement;
      if(setChecked){
        checkbox.checked = true;
        itemsToSelect.push(this.filteredItems[index].name);
      }else{
        checkbox.checked = false;
        itemsToSelect.pop();
      //  this.ngSelect.clearModel();
      this.ngSelect.writeValue(itemsToSelect);
      }
    });
    if(setChecked){
      this.ngSelect.writeValue(itemsToSelect);
    }
  }

  onSelectionChange(value): void {
    this.isSelectAllChecked = value;
    this.triggerClickOnAllCheckboxes(value);
  }

  applyFilterSearch(value: string) {
    this.eventBusService.emit('clearShare',true);
    this.applyFilterCallback.emit(value);
    this.searchPlanFC.reset();
    this.ngSelect.close();
  }


}
export class IAutocompleteItem {
  code: any;
  name: any;
  isGroup?: boolean;
  disabled?: boolean;
  selected?: boolean;
}

import {Injectable} from '@angular/core';
import {AmdocsAppsyncClientService} from 'amdocs-core-package';
import {Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';


@Injectable()
export class SessionService {
  constructor( private appSyncClient: AmdocsAppsyncClientService) {
  }


  setSessionData(): Observable<any> {
    const sessionQuery = `query GenerateDacSessionId {
      generateDacSessionId {
         isSuccess
       }
      }
  `;
    const paramsvalues = {
    };
    return this.appSyncClient.call(sessionQuery, 'GenerateDacSessionId', paramsvalues).pipe(
        mergeMap( (res: any): Observable<any> => {
          return of(res);
        }),
        catchError( err => {
          console.log(err);
          return of({error: err});
        })
    );
  }



}

import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appAutocompleteOff]'
})
export class AutocompleteOffDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const inputElement = this.el.nativeElement.querySelector('input');
    if (inputElement) {
      this.renderer.setAttribute(inputElement, 'autocomplete', 'off');
    }
  }
}
import { Component, OnDestroy } from '@angular/core';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { CONSTANTS } from 'src/app/constants';
@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnDestroy {
  public hideToast = true;
  public toastMessage='';
  private toastTimeout: any;
 
  constructor(private eventBus: AmdocsEventBusService) {
    this.eventBus.on('showError', (data) => {
    this.clearToastTimeout();
    this.toastMessage=data;
     this.showToastMessage();
     this.toastTimeout =setTimeout(() => {
     this.hideToastMessage();
  }, CONSTANTS.TOAST_TIME);
    },true);
 
    this.eventBus.on('removeError', (data) => {
       this.hideToastMessage();
      },true);
  }
 
  showToastMessage() {
    this.hideToast = false;
  }
 
  hideToastMessage() {
    this.hideToast = true;
  }
 
  private clearToastTimeout(): void {
    if (this.toastTimeout) {
      clearTimeout(this.toastTimeout);
      this.toastTimeout = null;
    }
  }
 
  ngOnDestroy(): void {
    this.clearToastTimeout();
  }
 
}

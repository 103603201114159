import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  AmdocsEventBusService,
} from 'amdocs-core-package';
import { CONSTANTS } from 'src/app/constants';
@Component({
  selector: 'amdocs-autocomplete',
  templateUrl: './amdocs-autocomplete.component.html',
  styleUrls: ['./amdocs-autocomplete.component.scss']
})
export class AutocompleteComponent implements OnChanges {


  @Input() label: string;
  @Input() items: IAutocompleteItem[];
  @Input() selectedItemCode: any=[];
  @Input() placeholder = 'select';
  @Input() virtualScroll = false;
  @Input() readOnly = false;
  @Input() highlightResults = true;
  @Input() clearable = true;
  @Input() hideLabel: boolean = false;
  @Input() hasHierarchy = true;
  @Input() customClass: string;
  @Output() itemChangedCallback: EventEmitter<IAutocompleteItem> = new EventEmitter();
  @Output() selectedItemCleared: EventEmitter<any> = new EventEmitter();
  @Output() selectOpen: EventEmitter<any> = new EventEmitter();
  @Input() errorsMessage: string;
  @Input() isRequired = false;
  @Input() disabled = false;
  @Input() noDropdown = false;
  @Input() isNotOpen = false;
  @Input() isRounded = false;
  @Input() isMultiple = false;
  @Input() selectIfOneItem = true;
  @Input() hasTooltip = false;
  @Input() tooltipContent: string;
  @Input() hasSearchBox = false;
  @Input() clearOnBackspace = true;
  @Input() searchPlaceholder = '';
  @Input() selectedItemsDisplayPosition: string = '';
  @Input() isPlaceHolder:boolean=true;
  @Input() isApplyButton:boolean=false;
  @Input() id:any='';
  @Input() isApply:boolean=false;
  @ViewChildren('checkbox') checkboxes: QueryList<ElementRef>;
  @ViewChild('selectAll') selectAll: ElementRef;
  @ViewChild('mySelect') ngSelect: NgSelectComponent;
  @ViewChild('searchInput') searchValue!: ElementRef;
  selectedItems = [];
  allSelected = false;
  filteredItems: IAutocompleteItem[];
  searchPlanFC = new UntypedFormControl();
  isAllChecked:boolean=false;
  isSelectAllChecked: boolean = false;
  public searchQuery: string = '';
  public mainValues: any;
  public tempId: string = '';
  @Output() applyFilterCallback: EventEmitter<any> = new EventEmitter();
  @ContentChild('autocompleteOptionTemplate') autocompleteOptionTemplate: TemplateRef<ElementRef>;
  constructor(private eventBusService: AmdocsEventBusService) {
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.tempId = this.id;
    if(this.selectedItemCode){
      this.mainValues =this.selectedItemCode
    }
    this.filteredItems = this.items;
    if (changes.items) {
      if (this.selectedItemCode?.length) {
        const tmpItems: any = changes.items.currentValue;
        this.handleItemSelectionChange(tmpItems);
      }
    }
  }

  ngOnInit(): void {

    this.eventBusService.on(
      'clearAll',
      (data: any) => {
        this.selectedItemCode = [];
      },
      true
    );
  }



  private handleItemSelectionChange(tmpItems: any): void {
    if (tmpItems && (tmpItems as any[]).find) {
      if (!(tmpItems as any[]).find(i => i.name === this.selectedItemCode)) {
        this.itemChangedEvent();
      }
    }
  }

  clear(item: any, event: Event,id:any,clear:any){
    event.stopPropagation();
    this.selectedItemCode = [];
    this.ngSelect.clearModel();
    if(clear=='clearCross'){
      this.applyFilterSearch(id)
    }
  }
  onSelectOpen(): void {
    if(this.noDropdown) {
      this.isNotOpen = true
      this.selectOpen.emit();
    }

    setTimeout(() => {
      this.isNotOpen = null
    }, 0)
    let allChecked = true;

    this.checkboxes.forEach((checkboxElementRef) => {
        const checkbox = checkboxElementRef.nativeElement as HTMLInputElement;
        if (this.selectedItems && Array.isArray(this.selectedItems)) {
          checkbox.checked = this.selectedItems.includes(checkbox.name);
        } else {
          checkbox.checked = false; // or any default value you prefer
        }
    });

  }

  onSelectClose(id: string) {
    this.tempId = id;
    this.ngSelect.close();
    this.selectedItemCode = this.selectedItemCode.filter((code) =>
      this.mainValues?.includes(code)
    );
    if (this.mainValues) {
      this.selectedItemCode = this.mainValues
    }

    else {
      this.selectedItemCode = [];
      this.ngSelect.clearModel();
    }

    this.eventBusService.emit(CONSTANTS.EVENTS.FILTER_CLOSE_EVENT, this.id);
    const formattedCategories = this.mainValues.map(category => ({ name: category })) ?? [];
    this.eventBusService.emit('cancelFilterApply',{
      value: id,
      selectedItemCode: formattedCategories,
      isSilentEvent: false,
    });
  }
  getValidItemCount(items: { count: number, name: string }[], filterItems: { count: number, name: string }[]): number {
    return items.filter(item =>
      filterItems.some(filter => item.name.toLowerCase() === filter.name.toLowerCase())
    ).length;
  }

  public filterItems(term: string): void {
    if (term) {
      this.filteredItems = this.items.filter(item =>
        item.name.toLowerCase().includes(term.toLowerCase())
      );
    } else {
      this.filteredItems = this.items; // Reset to all items if search term is cleared
    }
  }

  onClose(){
    if(!this.noDropdown) {
      this.isNotOpen = false
      this.searchPlanFC.reset();
    }
    if(this.noDropdown) {
      this.isNotOpen = true
      this.selectOpen.emit();
    }

    setTimeout(() => {
      this.isNotOpen = null
    }, 0)
  }

  close(){
  this.isNotOpen=false;
  this.searchPlanFC.reset();
  }

  updateCheckboxStates(): void {
    this.searchPlanFC.reset();
    this.onCheckboxClick();
    this.eventBusService.emit('filterOpened',true)
  }

  onSearch(term: string, item): boolean {
    if (!item || !item.name) {
      return false; // or any default value you prefer
    }

    const formattedTerm = term.toLowerCase();
    const formattedName = item.name.replace(',', '').toLowerCase();

    return formattedName.includes(formattedTerm);
  }

  onInputSearch(term: string) {
   this.searchQuery = term;
    if (term) {
      this.filteredItems = this.items.filter(item =>
        item.name.toLowerCase().includes(term.toLowerCase())
      );
    } else {
      this.filteredItems = this.items; // Reset to all items if search term is cleared
    }

  }

  private getSelectedItemsForSelectAll(): string[] {
    const selectAllCheckbox = this.selectAll?.nativeElement as HTMLInputElement;
    if (this.searchQuery === '') {
      return selectAllCheckbox.checked ? this.items.map(item => item.name) : [];
    } else {
      const filteredItems = this.searchQuery
        ? this.items.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
        : this.items;
      return selectAllCheckbox.checked ? filteredItems.map(item => item.name) : [];
    }
  }

  private getSelectedItemsForMultiple($event: any): string[] {
    return this.selectedItemCode?.every(elem => this.items.filter(i => i.name.toLowerCase().includes(elem.toLowerCase())))
      ? this.selectedItemCode
      : [];
  }

  private getSelectedItemForSingle(): any {
    return this.items.find(i => i.name === this.selectedItemCode);
  }

  public itemChangedEvent($event: any = ''): void {
    let selectedItem;

    if ($event?.target?.value === 'on') {
      selectedItem = this.getSelectedItemsForSelectAll();
      this.isSelectAllChecked = false;
    } else if ((this.isMultiple && $event && $event[0]?.name) || ($event.length === 0)) {
      selectedItem = this.getSelectedItemsForMultiple($event);
    } else {
      selectedItem = this.getSelectedItemForSingle();
    }
    if(selectedItem!=undefined){
      this.itemChangedCallback.emit(selectedItem);
    }
    this.onCheckboxClick(); // Code to be executed after a delay
  }

  onCheckboxClick() {
    setTimeout(() => {
      // Your existing code here

    const selectAllCheckbox = this.selectAll?.nativeElement as HTMLInputElement;
    if(selectAllCheckbox && this.filteredItems?.length != 0 && this.filteredItems?.length == this.checkboxes.filter((checkboxElementRef) => {
      const checkbox = checkboxElementRef.nativeElement as HTMLInputElement;
      return checkbox.checked;
    }).length)
    {
      selectAllCheckbox.checked = true;
      this.isAllChecked = true;
    }else{
      if(selectAllCheckbox){
      selectAllCheckbox.checked = false;
      this.isAllChecked = false;
      }
    }
  });

  }

  triggerClickOnAllCheckboxes(setChecked: boolean): void {
    this.isSelectAllChecked = setChecked;
    const itemsToSelect = [];
    const itemsToClear = [];
    this.checkboxes.forEach((checkboxElementRef,index) => {
      const checkbox= checkboxElementRef.nativeElement as HTMLInputElement;
      if(setChecked){
        checkbox.checked = true;
        itemsToSelect.push(this.filteredItems[index].name);
      }else{
        checkbox.checked = false;
        itemsToSelect.pop();
      this.ngSelect.writeValue(itemsToSelect);
      }
    });
    if(setChecked){
      this.ngSelect.writeValue(itemsToSelect);
    }
  }

  onSelectionChange(value): void {
    this.isSelectAllChecked = value;
    this.triggerClickOnAllCheckboxes(value);
  }

  applyFilterSearch(value: string) {
    this.tempId = value;
    this.mainValues = this.selectedItemCode;
    this.eventBusService.emit('clearShare', true);

    const formattedCategories = this.selectedItemCode.map(category => ({ name: category }));
    this.applyFilterCallback.emit({
      value: value,
      selectedItemCode: formattedCategories,
      isSilentEvent: true,
    });
    this.searchPlanFC.reset();
    this.ngSelect.close();
  }

  onNgSelectClose(){
    this.onInputSearch('');
  }


}


export class IAutocompleteItem {
  code: any;
  name: any;
  isGroup?: boolean;
  disabled?: boolean;
  selected?: boolean;
}

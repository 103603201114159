import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.scss']
})
export class ShimmerComponent implements OnInit {
  @Input() countOfRowsLoader: number;
  public loaderCards = Array.from(Array(1).keys());
  public loaderRows = Array.from(Array(8).keys());

  ngOnInit(): void {
    if (this.countOfRowsLoader) {
      this.loaderCards = Array.from(Array(this.countOfRowsLoader).keys());
    }
  }
}

import { Component } from '@angular/core';
@Component({
  selector: 'app-permission',
  templateUrl: './permission-issue.component.html',
  styleUrls: ['./permission-issue.component.scss']
})
export class PermissionComponent {
  constructor(){}

}

<div class="toast-message" *ngIf="!hideToast" [class.hide]="hideToast" [class.slideInDown]="!hideToast">
    <div class="toast-message-content d-flex">
        <div class="toast-message-icon">
            <img src="/assets/icons/notification.svg" alt="alert-icon" />
        </div>
        <div class="toast-message-text">
            <p>{{ toastMessage }}</p>
        </div>
        <div class="toast-message-close">
            <img src="/assets/icons/close.svg" alt="close-icon" (click)="hideToastMessage()" />
        </div>
    <span></span>


<div class="container-fluid no-gutters px-0" #titlesection data-qa="banner-meta">

    <div class="card w-100 banner-page" id="banner-page">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" data-qa="breadcrumb">
          <li class="breadcrumb-item"><a (click)="navigatetoHome()" routerLink="/">Home</a></li>
          <li (click)="navigateToProductLine()" class="breadcrumb-item"><a>{{productLineTitle}}</a> </li>
          <li class="breadcrumb-item active" aria-current="page">{{docsTitle['KIN']}}</li>
        </ol>
      </nav>
      <div class="mainDiv">


        <div class="hstack gap-2 ms-5">
            <div class="vstack gap-2 ms-3">
                <div class="sub-header mt-4 mb-4">
                    {{title}}
                    <span data-qa="banner-metatitle" *ngIf="this.docsTitle && this.docsTitle['APrdctTre'] && this.docsTitle['APrdctTre'].length > 1">
                        ...
                        <div>{{showMoreProductLines}}</div>
                    </span>
                </div>

            </div>
        </div>
        <div class="allButton">


        <div class="selection-btn">
          <a data-qa="product-line-download"><img src="/assets/icons/downloadSelected.svg">
            <span class="shareText">Download</span>
          </a>

        </div>
        <div class="selection-btn" (click)="shareCourse()">
          <a [href]="mailtoLink" target="_blank" >
            <img src="/assets/icons/shareSelected.svg"  alt="share" />
            <span class="shareText">Share</span>

          </a>
        </div>
      </div>
    </div>
  </div>
</div>

import {
  Component,
  ViewChild,
  Inject,
  ElementRef,
  OnInit,
  EventEmitter,
  Output,
  HostListener,
  Input
} from '@angular/core';
import { CONSTANTS } from 'src/app/constants';
import {
  AmdocsEventBusService,
  AmdocsDeviceService,
  AmdocsUserService,
} from 'amdocs-core-package';
import { FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { DownloadDocumentService } from 'src/app/service/downloadDocument.service';
import { catchError, Observable, of, tap } from 'rxjs';

@Component({
  selector: 'app-cards-menu',
  templateUrl: './cards-menu.component.html',
  styleUrls: ['./cards-menu.component.scss'],
})
export class CardsMenuComponent implements OnInit {
  courseData: any = {};
  @Output() close = new EventEmitter<void>();
  public isMobile: boolean = false;
  public menuOpen = false;
  public togglemenuOpen = false;
  public assetsPath = CONSTANTS.ASSETS_URL;
  public posLeft: any;
  public posTop: any;
  public isMenu: boolean = false;
  public resultsWrapperHeight: number;
  public employees: any[] = null;
  public employeeSearchControl: FormControl;
  public nextToken: string = 'learning';
  public showResults = false;
  public isFromSearchHistory = false;
  public showEndOfResults = false;
  public searchError: any = null;
  public isLoading = false;
  shareCourse: boolean = false;
  keyword: string = 'displayName';
  shareButton: boolean = false;
  isLoadingResult: boolean = false;
  select: boolean = false;
  email: string = '';
  emailBody: string = '';
  selectedEmpNames: string = '';
  peopleToShareWith: string = '';
  public id: string[] = [];
  public isContinueLearning: boolean = false;
  public item: any;
  public employeeList: any[] = null;
  public historyList: any[] = null;
  historyIcon: boolean = false;
  public addHistory = false;
  public markDisabled:boolean=false;
  public historyData: string = '';
  public href: string = "";
  mailtoLink: string;
  public hrefLink: string;
  selectedItems: any[] = [];
  openedModal:string = ''
  menuId:string='';
  event$
  @ViewChild('cardmenu') cardmenu!: ElementRef;
  @ViewChild('auto') auto: any;
  @ViewChild('inputSearch') inputSearchElement: ElementRef;
  @ViewChild('loadMore') loadMorePlaceholderElement: ElementRef;
  @ViewChild('searchResults') searchResultsElement: ElementRef;
  @Input() cardIndex: any = 0;
  public openMenu:string='';
  size: any;
  courseDetailsComp: any;
  public menu:any=[];
  public cardData:any=[]
  constructor(
    private deviceService: AmdocsDeviceService,
    private eventBusService: AmdocsEventBusService,
    public amdocsUserService:AmdocsUserService,
    private el: ElementRef,
    private downloadService: DownloadDocumentService,
    @Inject(DOCUMENT) private document: Document
  ) {


  }
  
  private intersectionObserver: IntersectionObserver;
  setposition(data: any) {
    this.posLeft = this.getOffset(data).left;
    this.posTop = this.getOffset(data).top;
    this.cardmenu.nativeElement.style.top =
      this.posTop - CONSTANTS.CARDMENU.CURRENT_CONFIGURATION.TOP + 'px';
    this.cardmenu.nativeElement.style.left =
      this.posLeft + 'px';
  }

  public getOffset(el: any) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX-125,
      top: rect.top + window.scrollY +70,
    };
  }

  ngOnInit(): void {
    this.menuOpen = false;
    this.isMobile = this.deviceService.isMobile();
    this.eventBusService.on(
      CONSTANTS.EVENTS.OPEN_OPTION_MODAL,
      (data: any) => {
        if(this.menuId!=data.id){
        this.openMenu=data.id;
        this.menu[data.id]=true;
        this.updateMailtoLink(data.data);
        this.cardData=data.data;
        this.menu[data.id]=!this.menuOpen;
        this.togglemenu();
        this.menuId=data.id;
        }else{
          this.menuId='';
          //this.openMenu=data.id;
          //this.menu[data.id]=true;
        }
      },
      true
    );
  }

  download(item): void {
    this.eventBusService.emit(CONSTANTS.EVENTS.DOCUMENT_LOADER, true);
    const documentId = item?.DocNum;
    const documentType = item?.FileT;
    const documentName = item?.KIN[0];
    this.eventBusService.emit('', item);

    this.downloadService.getDownload(documentId)
      .pipe(
        tap((response: any) => this.handleApiResponse(response, documentType, documentName)),
        catchError(error => this.handleApiError(error))
      )
      .subscribe();
  }

  handleApiResponse(response: any, documentType: string, documentName: string): void {
    const headers = response.headers;
    const data = response.body;
    this.eventBusService.emit(CONSTANTS.EVENTS.DOCUMENT_LOADER, false);

    // Convert the byte stream to a Blob
    if (data.getDocumentContent.ContentResponse) {
      const byteArray = this.convertByteStreamToBlob(data.getDocumentContent.ContentResponse.FileByteStream);
      const { mimeType, documentext } = this.determineMimeType(documentType);
      this.createDownloadLink(byteArray, mimeType, documentName, documentext);
    }
    this.eventBusService.emit(CONSTANTS.EVENTS.DOCUMENT_LOADER, false);
  }

  handleApiError(error: any): Observable<null> {
    this.eventBusService.emit(CONSTANTS.EVENTS.DOCUMENT_LOADER, false);
    console.error('Error:', error);
    return of(null); // Return a fallback value or empty observable
  }

  convertByteStreamToBlob(byteStream: string): Uint8Array {
    const byteCharacters = atob(byteStream);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
  }

  determineMimeType(documentType: string): { mimeType: string, documentext: string } {
    let mimeType = 'application/octet-stream'; // Default MIME type
    let documentext = documentType;

    if (documentType?.toUpperCase() === 'PDF') {
      mimeType = 'application/pdf';
      documentext = 'pdf';
    } else if (documentType?.toUpperCase() === 'EXCEL' || documentType?.toUpperCase() === 'EXCELTEXTREPORT') {
      mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      documentext = 'xlsx';
    } else if (documentType?.toUpperCase() === 'WORD' || documentType?.toUpperCase() === 'MSW12') {
      mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      documentext = 'docx';
    }
      else if (documentType?.toUpperCase() === 'MSW8') {
      mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      documentext = 'doc';
    } else if (documentType?.toUpperCase() === 'ZIP' || documentType?.toUpperCase() === 'APPLICATION/ZIP' || documentType?.toUpperCase() === 'HTML') {
      mimeType = 'application/zip';
      documentext = 'zip';
    }

    return { mimeType, documentext };
  }

  createDownloadLink(byteArray: Uint8Array, mimeType: string, documentName: string, documentext: string): void {
    const blob = new Blob([byteArray], { type: mimeType });
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = `${documentName}.${documentext}`; // Set the file name and extension
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl); // Clean up the URL
  }

  ngOnDestroy(): void {
    this.removeScrollListening();
  }
  updateMailtoLink(data : any) {
    this.hrefLink = window.location.origin;
    const docID = encodeURIComponent(data.UnqI);  // encoding the data.UnqI value
    const subject = 'Document(s) from the Amdocs documentation portal'
    const body = encodeURIComponent(`Hi,

The following document(s) from the Amdocs documentation portal might interest you:

${data.KIN}
${this.hrefLink}/document?DocUID=${docID}&SOSessionID=&StartPage=`);
    this.mailtoLink = `mailto:?subject=${subject}&body=${body}`;  }




  setResultsWrapperHeight(): void {
    const currentBodyHeight = this.deviceService.screenHeight;
    this.resultsWrapperHeight = currentBodyHeight
      ? currentBodyHeight - 47
      : this.document.body.clientHeight - 47;
  }



  onSearchInputFocus(): void {
    console.log('Focussed');
  }
  onFocused(e) {
    // do something when input is focused

  }

  public setPreviousPosition(visible) {

    this.cardmenu.nativeElement.style.bottom =
      CONSTANTS.CARDMENU.PREVIOUS_CONFIGURATION.BOTTOM;
    this.cardmenu.nativeElement.style.left =
      CONSTANTS.CARDMENU.PREVIOUS_CONFIGURATION.LEFT;
    this.cardmenu.nativeElement.style.top =
      CONSTANTS.CARDMENU.PREVIOUS_CONFIGURATION.TOP;
      this.cardmenu.nativeElement.style.display = visible ?'block':'none';
  }

  public togglemenu(): void {
    this.shareCourse = false;
    this.menuOpen = !this.menuOpen;
  }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if(!this.isClickInsideCardMenu(event)){
      this.openMenu='';
      this.menuId='';
    }
  }

  isClickInsideCardMenu(event: MouseEvent): boolean {
    let targetElement = event.target as HTMLElement;
    while (targetElement) {
      if (targetElement.classList && targetElement.classList.contains('openOptions')) {
        return true;
      }
      targetElement = targetElement.parentElement;
    }

    return false;
  }

  removeScrollListening(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
      this.intersectionObserver = null;
    }
  }
}

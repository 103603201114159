import {Component, Input, OnChanges, OnInit, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import { SaveFavouriteService } from 'src/app/service/saveFavourite.service';
import { MarkUnMarkFavouriteProductLine } from 'src/app/service/markFavourite.service';
import { AmdocsEventBusService } from 'amdocs-core-package';

@Component({
  selector: 'app-quicklinks-menu',
  templateUrl: './quicklinks-menu.component.html',
  styleUrls: ['./quicklinks-menu.component.scss']
})
export class QuicklinksMenuComponent implements OnInit,OnChanges{
  @Input() quickLinks: boolean;
  public menuOpen = false;
  public assetsPath='';
  public userName:string='';
  public filteredItems: any = [];
  @Output() closeEvent = new EventEmitter<boolean>();
  public markedFavProducts: any =  [

];
  public searchItem: any = '';
  public isFavoritesAvailable: boolean = true;

  

  constructor(private router:Router,private saveFavourite:SaveFavouriteService,private markUnmarkFavouriteService:MarkUnMarkFavouriteProductLine,private eventBus: AmdocsEventBusService) {
  }

  ngOnInit(){
    if(this.quickLinks){
      this.open();
    }
    console.log('debugger');
    this.getMyFavouriteProductList();
  }

  getProductLine(data: any): void {
    this.eventBus.emit('productLineListing', data);
  }

  getMyFavouriteProductList(): void {
    console.log('quicklinks fn')
    this.markUnmarkFavouriteService
      ?.getFavouriteProductList()
      .subscribe((list) => {
        if(list){
        this.isFavoritesAvailable = Boolean(
          list?.getMyFavouriteProductLineList?.length
        );
        this.markedFavProducts = list?.getMyFavouriteProductLineList;
        //this.saveFavourite.updateFavourites(this.markedFavProducts);
        this.eventBus.emit('favourite', this.markedFavProducts);
        }
      });
  }

  ngOnChanges(){
    if(this.quickLinks){
      this.open();
    }
  }

  public open(): void {
    this.menuOpen = true;
    this.quickLinks=false;
  }

  public close(): void {
    this.menuOpen = false;
    this.closeEvent.emit(false);
  }

  public showMenuItem(item:any): boolean {
    return true;
  }

  public removeFavourites(item,isMarkFavourite){
    let param = {
      saveFaouriteProdcutLineInput: {
        SysID:item.SysID,
        name:item.name,
        isMarkFavourite,
      },
    };
    this.markUnmarkFavouriteService
    ?.markUnmarkFavourite(param)
    ?.subscribe((data) => {
      this.isFavoritesAvailable =
        data?.markUnMarkFavouriteProductLine?.isSuccess;
        if(this.isFavoritesAvailable){
        const index = this.markedFavProducts.findIndex(favItem => favItem.SysID === item.SysID);

        if (isMarkFavourite) {
          // If item is not in the array (index === -1), add it
          if (index === -1) {
            this.markedFavProducts.push(item);
          }
        } else {
          // If item is in the array, remove it
          if (index !== -1) {
            this.markedFavProducts.splice(index, 1);
          }
        }
        this.eventBus.emit('favourite', this.markedFavProducts);
      }else{
        item.isMarkFavourite=!isMarkFavourite;
        
      }
      this.saveFavourite.updateFavourites(this.markedFavProducts);
    });
    
    
  }
}
